import { LinearProgress } from "@material-ui/core";
import { ErrorBox } from "components/error";
import { Permission } from ".";
import AccessDenied from "./AccessDenied";
import RestrictedRealmDebugger from "./RestrictedRealmDebugger";
import useRequestUserPermissions from "./useRequestUserPermissions";

interface RestrictedRealmProps {
  /** Permissions required to access the content (children of this component) */
  requiredPermissions: AtLeastOnePermission;

  /** If silent the component does display either the children (permissions available) or nothing.
   *  No loading indicator, missing permissions or fallback will be shown. */
  silent?: boolean;

  /** The content that requires the permission to be accessed. */
  children: React.ReactNode | RenderFunc;

  /** By default a access denied component is shown reporting the missing permissions.  */
  fallback?: React.ReactNode;
}

interface RenderProps {
  accessGranted: boolean;
}

type RenderFunc = (props: RenderProps) => JSX.Element;

export type AtLeastOnePermission = [Permission, ...Permission[]];

/**
 * Component to specify access rights for a react subtree.
 * 
 * @example
 * Can be used in different ways:
 * 
 *  | Props       | Access check |   Access granted | Access denied
 *  | (default)   | indicator    | displays content | access denied
 *  | silent      | nothing      | displays content | nothing
 *  | fallback    | indicator    | displays content | fallback

 */
export default function RestrictedRealm({
  requiredPermissions,
  silent = false,
  children,
  fallback
}: RestrictedRealmProps) {
  const {
    data: { accessGranted, missingPermissions },
    loading,
    error
  } = useRequestUserPermissions(requiredPermissions);

  if (error)
    return (
      <ErrorBox apolloError={error} title="Unable to access user permissions" />
    );

  return (
    <RestrictedRealmDebugger
      requiredPermissions={requiredPermissions}
      accessGranted={accessGranted}
      missingPermissions={missingPermissions}
    >
      {typeof children === "function" ? (
        children({ accessGranted })
      ) : (
        <>
          {accessGranted ? (
            <>{children}</>
          ) : !silent ? (
            <>
              {loading && <LinearProgress />}
              {fallback !== undefined
                ? fallback
                : missingPermissions && (
                    <AccessDenied missingPermissions={missingPermissions} />
                  )}
            </>
          ) : null}
        </>
      )}
    </RestrictedRealmDebugger>
  );
}

import {
  Button,
  Grid,
  LinearProgress,
  Paper,
  Typography
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import BasicCard from "components/card/BasicCard";
import { ErrorBox } from "components/error";
import { useQueryUseCaseList } from "features/use-case";
import { Formik, FormikErrors, FormikValues } from "formik";
import gql from "graphql-tag";
import useResettableMutation from "hooks/useResettableMutation";
import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import defaultIcon from "tile.svg";
import { createRoute } from "utils/url";
import ChangesPreview from "./ChangesPreview";
import usePublishFormReducer, { State } from "./hooks/usePublishFormReducer";
import PublishForm from "./PublishForm";
import {
  PublishUseCase,
  PublishUseCaseVariables
} from "./schema/PublishUseCase";

const mutationPublishUseCase = gql`
  mutation PublishUseCase($useCaseDefinition: UseCaseDefinitionInput!) {
    cloudPlatform {
      addUseCase(useCaseDefinition: $useCaseDefinition) {
        detail {
          version
          key
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex: {
      display: "flex",
      flexDirection: "column"
    },
    indicatorWrapper: {
      position: "sticky",
      width: "100%",
      zIndex: 1300
    },
    paper: { padding: theme.spacing(3) },
    previewContainer: {
      display: "flex",
      borderLeft: "solid 1px #F5F7F7",
      [theme.breakpoints.down("md")]: {
        borderTop: "solid 1px #F5F7F7"
      }
    },
    title: {
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(1)
    },
    previewUseCase: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      width: 350,
      height: 200
    },
    link: { display: "inline-flex", textDecoration: "none" },
    fab: {
      color: theme.palette.common.white,
      position: "absolute",
      bottom: theme.spacing(3),
      right: theme.spacing(3),
      textTransform: "capitalize"
    },
    cancelButton: {
      color: "#FFFFFF",
      marginRight: theme.spacing(1),
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        opacity: 0.8
      }
    },
    createButton: {
      color: "#FFFFFF",
      backgroundColor: theme.palette.primary.dark,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
        opacity: 0.8
      }
    },
    disabledButton: {
      backgroundColor: "#C7CFD4 !important",
      color: "#68777B !important"
    },
    margin: { marginTop: theme.spacing(2) }
  })
);

interface PublishFormWrapperProps {
  initialState?: State;
  updateUseCase?: boolean;
}

export default function PublishFormWrapper({
  initialState = {
    name: "",
    key: "",
    autoGeneratedKey: true,
    description: "",
    tags: []
  },
  updateUseCase = false
}: PublishFormWrapperProps) {
  const classNames = useStyles({});

  const [
    publish,
    { loading, error: publishUseCaseError, reset: resetPublishUseCaseError }
  ] = useResettableMutation<PublishUseCase, PublishUseCaseVariables>(
    mutationPublishUseCase,
    {
      onCompleted: mutationResult => {
        const createdUseCase = mutationResult.cloudPlatform.addUseCase?.detail;
        if (!createdUseCase) return;
        const { key, version } = createdUseCase;
        history.push(
          createRoute(`/use-case-manager/use-case/${key}@${version}/create`)
        );
        history.push(createRoute(`/use-cases/${key}/${version}`));
      }
    }
  );

  const { data: allUseCases } = useQueryUseCaseList();
  const [state, dispatch] = usePublishFormReducer(initialState);

  const history = useHistory();

  const { name, image, description, tags, calculationRuleAddress, key } = state;
  const initialValues = {
    name,
    key,
    description,
    calculationRuleAddress: ""
  };

  const allUseCasesKeys = allUseCases?.cloudPlatform.useCases?.map(
    ({ detail: { key, version } }) => ({ key, version })
  );

  const isExistedKey = (a: string) => allUseCasesKeys?.find(c => c.key === a);
  const [debouncedKey, setDebouncedKey] = useState(key);
  const delayed = useRef(debounce(setDebouncedKey, 500)).current;

  useEffect(() => {
    delayed(key);
  }, [delayed, key]);

  const isValidName = (value: string) => {
    return value
      .split("")
      .map(c => c.charCodeAt(0))
      .every(c => c <= 127);
  };

  const validate = (values: FormikValues) => {
    const errors: FormikErrors<FormikValues> = {};

    if (!updateUseCase) {
      if (values.key === "") {
        errors.key = "Use case Key is required";
      } else if (isExistedKey(values.key)) {
        errors.key = "This key already exists. You can publish a new version";
      } else if (/[^A-Za-z0-9_.-]/.test(values.key)) {
        errors.key = "Key must only contain lowercase letters and digits";
      }
      if (
        values.name &&
        (values.key || key) &&
        !isExistedKey(values.key) &&
        !values.calculationRuleAddress
      ) {
        errors.calculationRuleAddress = "Calculation Rule is required";
      }
    } else {
      if (values.name && !values.calculationRuleAddress) {
        errors.calculationRuleAddress = "Calculation Rule is required";
      }
    }
    if (values.name === "") {
      errors.name = "Use case Name is required";
    } else if (!isValidName(values.name)) {
      errors.name = "Name must have only Ascii characters";
    }

    return errors;
  };
  return (
    <>
      <div className={classNames.indicatorWrapper}>
        {loading ? <LinearProgress /> : null}
      </div>
      <Grid container justifyContent="center" alignItems="center">
        <Grid lg={10} xl={8} container item justifyContent="center">
          <Paper className={classNames.paper}>
            <Grid container spacing={6}>
              <Grid item container sm={12} md={7}>
                <Typography className={classNames.title}>
                  NEW USE CASE
                </Typography>
                <Formik
                  initialValues={initialValues}
                  validate={validate}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                      setSubmitting(false);
                    }, 500);
                  }}
                >
                  {({
                    submitForm,
                    isSubmitting,
                    dirty,
                    isValid,
                    handleReset,
                    ...restOfProps
                  }) => {
                    return (
                      <div className={classNames.flex}>
                        <PublishForm
                          {...restOfProps}
                          state={state}
                          dispatch={dispatch}
                          updateUseCase={updateUseCase}
                          version={isExistedKey(key)?.version}
                        />
                        <div>
                          <Link
                            className={classNames.link}
                            to={createRoute(`/use-cases`)}
                          >
                            <Button
                              color="primary"
                              variant="contained"
                              className={classNames.cancelButton}
                            >
                              Cancel
                            </Button>
                          </Link>
                          <Button
                            disabled={!dirty || !isValid}
                            id="publish-use-case"
                            variant="contained"
                            classes={{
                              root: classNames.createButton,
                              disabled: classNames.disabledButton
                            }}
                            className={classNames.createButton}
                            data-testid={
                              !updateUseCase
                                ? "publish-use-case"
                                : "publish-use-case-version"
                            }
                            onClick={() => {
                              submitForm();
                              if (!state.calculationRuleAddress) return;
                              publish({
                                variables: {
                                  useCaseDefinition: {
                                    calculationRuleAddress:
                                      state.calculationRuleAddress,
                                    key: state.key,
                                    name: state.name,
                                    description: state.description,
                                    image: state.image,
                                    tags: state.tags
                                  }
                                }
                              });
                            }}
                          >
                            {!updateUseCase
                              ? "Create Use Case"
                              : "Create new version"}
                          </Button>
                        </div>
                      </div>
                    );
                  }}
                </Formik>
              </Grid>
              <Grid
                item
                container
                sm={12}
                md={5}
                direction="column"
                alignItems="stretch"
                className={classNames.previewContainer}
                data-testid="preview"
              >
                <Grid item>
                  <Typography align="left" className={classNames.title}>
                    PREVIEW
                  </Typography>
                </Grid>
                <Grid item container>
                  <div className={classNames.previewUseCase}>
                    <BasicCard
                      name={name}
                      key={key}
                      tags={tags}
                      icon={image ? image : defaultIcon}
                      description={description}
                    />
                  </div>
                </Grid>
                <Grid item container spacing={2}>
                  {calculationRuleAddress && (
                    <ChangesPreview
                      useCaseKey={debouncedKey}
                      calculationRuleAddress={calculationRuleAddress}
                    />
                  )}
                </Grid>
                {publishUseCaseError && (
                  <div className={classNames.margin}>
                    <ErrorBox
                      apolloError={publishUseCaseError}
                      title="Use case publish failed!"
                      onClose={() => resetPublishUseCaseError()}
                      closable
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

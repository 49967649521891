import { Button, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  overlay: {
    backgroundColor: "#f5f7f7",
    fontSize: 16,
    color: "#555",
    padding: theme.spacing(3),
    border: `solid 1px #d9dcde`
  },
  flex: {
    pointerEvents: "all",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  button: {
    textTransform: "none",
    marginTop: theme.spacing(1),
    color: "#3AA9AE"
  }
}));

type NoCalculationsFoundProps = {
  useCaseKey: string;
  navigateToCalculate: () => void;
};

const NoCalculationsFound = ({
  useCaseKey,
  navigateToCalculate
}: NoCalculationsFoundProps) => {
  const classNames = useStyles({});
  return (
    <div className={classNames.root}>
      <div className={classNames.overlay}>
        <div className={classNames.flex}>
          <Typography>
            There are no calculations scheduled for: {useCaseKey}
          </Typography>
          <Button
            onClick={() => navigateToCalculate()}
            className={classNames.button}
          >
            Schedule a calculation
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NoCalculationsFound;

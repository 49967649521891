import {
  createStyles,
  Grid,
  IconButton,
  LinearProgress,
  makeStyles,
  Modal,
  Paper,
  Theme,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import UpdateIcon from "@material-ui/icons/Update";
import { TechnicalChangelog } from "features/use-case";
import { ReleaseType } from "model/schema/globalTypes";
import React, { useState } from "react";
import useQueryChangePreview, {
  NextVersionInfoProps,
  Schema,
  SchemaDiff
} from "./hooks/useQueryChangePreview";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      position: "absolute",
      top: "10vh",
      left: "10vw",
      width: "80vw",
      height: "80vh",
      overflow: "auto",
      padding: theme.spacing(2),
      display: "flex"
    },
    progress: {
      margin: theme.spacing(2),
      width: "100%"
    },
    content: {
      paddingTop: theme.spacing(2),
      display: "flex"
    },
    version: {
      color: "#B1B1B1"
    },
    margin: { marginTop: theme.spacing(1) }
  })
);

type ChangesPreviewComponentProps = {
  version: string;
  releaseType: ReleaseType;
  schemaDiff?: SchemaDiff;
  schema?: Schema;
};

const ReleasePreview: React.FC<ChangesPreviewComponentProps> = ({
  schemaDiff,
  schema,
  version,
  releaseType
}) => {
  const jss = useStyles();
  const [showChangelog, setShowChangelog] = useState(false);
  const toggleChangelog = () => setShowChangelog(!showChangelog);

  return (
    <>
      <Grid item container data-testid="version" className={jss.margin}>
        <Grid item container xs={6}>
          <Typography className={jss.version}>Expected next version</Typography>
        </Grid>
        <Grid item container xs={6} alignItems="center">
          <Typography className={jss.version}>{version}</Typography>
        </Grid>
      </Grid>

      <Grid item container data-testid="releaseType">
        <Grid item container xs={6}>
          <Typography className={jss.version}>Expected release type</Typography>
        </Grid>
        <Grid item container xs={6} alignItems="center">
          <Typography className={jss.version}>{releaseType}</Typography>
        </Grid>
      </Grid>

      {schema && schemaDiff && (
        <>
          <Grid item container data-testid="changelog">
            <Grid item container xs={6}>
              <Typography variant="subtitle2">Show Changes</Typography>
            </Grid>
            <Grid item container xs={6} alignItems="center">
              <IconButton size="small" onClick={toggleChangelog}>
                <UpdateIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Modal open={showChangelog} onClose={toggleChangelog}>
            <Paper className={jss.modal}>
              <Grid container direction="column">
                <Grid item container justifyContent="space-between">
                  <Typography> Changelog </Typography>
                  <IconButton
                    size="small"
                    onClick={toggleChangelog}
                    data-testid="changelog-close"
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Grid item container className={jss.content} xs>
                  <TechnicalChangelog schemaDiff={schemaDiff} schema={schema} />
                </Grid>
              </Grid>
            </Paper>
          </Modal>
        </>
      )}
    </>
  );
};

const ChangesPreview = ({
  useCaseKey,
  calculationRuleAddress
}: NextVersionInfoProps) => {
  const jss = useStyles();

  const { data, loading } = useQueryChangePreview({
    useCaseKey,
    calculationRuleAddress
  });

  if (loading) return <LinearProgress className={jss.progress} />;
  if (!data) return null;

  const { useCase } = data?.cloudPlatform;
  if (useCase === null) return <InitialVersionPreview />;
  const nextVersion = useCase.detail.nextVersion;
  if (!nextVersion) return null;
  const { releaseType, schemaDiff, schema, version } = nextVersion;

  return (
    <ReleasePreview
      version={version}
      releaseType={releaseType}
      schema={schema}
      schemaDiff={schemaDiff}
    />
  );
};

export default ChangesPreview;

const InitialVersionPreview = () => {
  return <ReleasePreview version="1.0.0" releaseType={ReleaseType.Initial} />;
};

import React from "react";
import {
  components,
  GroupTypeBase,
  OptionTypeBase,
  ValueContainerProps
} from "react-select";

const { ValueContainer, Placeholder } = components;
const ValueContainerWithCustomPlaceholder: React.ComponentType<
  ValueContainerProps<
    OptionTypeBase,
    boolean,
    GroupTypeBase<{
      label: string;
      value: string;
    }>
  >
> = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder
        {...props}
        isFocused={props.selectProps.isFocused}
        isDisabled={props.selectProps.isDisabled || false}
        innerProps={{ style: {} }}
      >
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, child =>
        React.isValidElement(child) &&
        (child as React.ReactElement<any>).type !== Placeholder
          ? child
          : null
      )}
    </ValueContainer>
  );
};

export default ValueContainerWithCustomPlaceholder;

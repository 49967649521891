import { Grid } from "@material-ui/core";
import { Permission } from "features/access-management";
import useCaseDesignerImage from "./landingPageIcons/use-case-designer.svg";
import useCaseManagerImage from "./landingPageIcons/use-case-manager.svg";
import useCasesImage from "./landingPageIcons/use-cases.svg";
import usersImage from "./landingPageIcons/users.svg";
import workspacesImage from "./landingPageIcons/workspaces.svg";
import SpaceCard from "./SpaceCard";

export default function LandingPage() {
  return (
    <Grid container spacing={4}>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item>
            <SpaceCard
              name="Projects"
              requiredPermissions={[Permission.ReadProjects]}
              image={useCaseDesignerImage}
              description="Design use cases with graphical programming inside the supply chain suite. Use uploaded data to make calculations and analyze the results."
              targetRoles={["SCS modeler", "customer"]}
              link="/projects"
              testid="projects-button"
            />
          </Grid>
          <Grid item>
            <SpaceCard
              name="Workspaces"
              requiredPermissions={
                [
                  Permission.ReadWorkspaces,
                  "no one has it" /* hack to forbid workspace access as /workspaces route is not yet implemented  */
                ] as any
              }
              image={workspacesImage}
              description="Identify users currently working on a workspace, see which projects share a common workspace and configure available working seats."
              targetRoles={["Project leader"]}
              link="/workspaces"
              testid="workspaces-button"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item>
            <SpaceCard
              name="Use cases"
              requiredPermissions={[Permission.ReadUseCases]}
              image={useCasesImage}
              description="Use uploaded data to make calculations and analyze the results."
              targetRoles={["customer"]}
              link="/use-cases"
              testid="use-cases-button"
            />
          </Grid>
          <Grid item>
            <SpaceCard
              name="Use case manager"
              requiredPermissions={[Permission.ReadUseCases]}
              image={useCaseManagerImage}
              description="Manage and release use cases, grant access and monitor usage."
              targetRoles={["SCS modeler", "Project leader"]}
              link="/use-case-manager"
              testid="use-case-manager-button"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item>
            <SpaceCard
              name="Users"
              requiredPermissions={[Permission.ReadOtherUsers]}
              image={usersImage}
              description="Manage users and user permissions."
              targetRoles={["Project leader"]}
              link="/users"
              testid="users-button"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

import { LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ColDef } from "ag-grid-community";
import { ApolloError } from "apollo-client";
import { ErrorBox } from "components/error";
import Header from "components/tableSetExplorer/Header";
import TableSetGrid from "components/tableSetExplorer/TableSetGrid";
import { useRef } from "react";
import { DeepOmit } from "utils/typescript";
import { QueryCalculationResultInputData_cloudPlatform_useCases_calculation_inputData_tableSet_tables } from "./hooks/schema/QueryCalculationResultInputData";
import { QueryCalculationResultOutputData_cloudPlatform_useCases_calculation_resultData_tableSet_tables } from "./hooks/schema/QueryCalculationResultOutputData";

const useStyles = makeStyles(theme => ({
  margin: {
    marginBottom: theme.spacing(1)
  },
  flexContainer: { display: "flex", flexDirection: "column", height: "100%" },
  tableView: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto"
  }
}));

type InputDataTablesProps = DeepOmit<
  QueryCalculationResultInputData_cloudPlatform_useCases_calculation_inputData_tableSet_tables,
  "__typename"
>;
type ResultDataTablesProps = DeepOmit<
  QueryCalculationResultOutputData_cloudPlatform_useCases_calculation_resultData_tableSet_tables,
  "__typename"
>;

interface CalculationResultTableProps {
  useCaseKey: string;
  useCaseVersion: string;
  calculationId: string;
  name: string;
  columnDefs?: ColDef[];
  loadData: (
    useCaseKey: string,
    useCaseVersion: string,
    calculationId: string,
    name: string,
    skip: number,
    take: number
  ) => Promise<InputDataTablesProps | ResultDataTablesProps | null | undefined>;
  error?: ApolloError;
  loading: boolean;
  backgroundColor: string;
}
export const CalculationResultTable = ({
  useCaseKey,
  useCaseVersion,
  calculationId,
  name,
  columnDefs,
  loadData,
  error,
  loading,
  backgroundColor
}: CalculationResultTableProps) => {
  const { margin, flexContainer, tableView } = useStyles();
  const TableSetGridReference = useRef<any>(null);
  const current = TableSetGridReference.current;
  const autoSize = () => {
    const columnsIds = current
      ?.grid()
      .columnApi.columnController.allDisplayedColumns.map(
        ({ colId }: { colId?: string }) => colId
      );

    current?.grid().columnApi.columnController.autoSizeColumns(columnsIds);
  };

  const sizeToFit = () => {
    current?.grid().api.sizeColumnsToFit();
  };

  if (error) return <ErrorBox apolloError={error} />;
  if (loading) return <LinearProgress className={margin} />;
  return (
    <div className={flexContainer}>
      <div key={name} className={tableView}>
        {/* @ts-ignore */}
        <Header
          name={name}
          resizeButtons={true}
          autoSize={autoSize}
          sizeToFit={sizeToFit}
          backgroundColor={backgroundColor}
        />
        <TableSetGrid
          key={name}
          columnDefs={columnDefs}
          name={name}
          useCaseKey={useCaseKey}
          /* @ts-ignore */
          useCaseVersion={useCaseVersion}
          calculationId={calculationId}
          loadData={loadData}
          ref={TableSetGridReference}
        />
      </div>
    </div>
  );
};

export default CalculationResultTable;

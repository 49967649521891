import { createStyles, makeStyles, Typography } from "@material-ui/core";
import { HTMLAttributes } from "react";

const useStyles = makeStyles(() =>
  createStyles({
    minAndMax: {
      gridArea: "minAndMax",
      minHeight: 400,
      position: "relative"
    },
    label: {
      whiteSpace: "nowrap",
      marginRight: 4,
      fontSize: "0.8rem",
      fontWeight: "bold"
    },
    number: { fontSize: "0.8rem", fontWeight: "bold" },
    flex: {
      position: "absolute",
      left: 0,
      display: "flex",
      justifyContent: "space-between"
    }
  })
);

type CapacityValuesProps = {
  min: number;
  className?: HTMLAttributes<HTMLDivElement>["className"];
  max: number;
  desired: number;
  maxLimit: number;
};

export default function CapacityValues({
  min,
  max,
  desired,
  maxLimit
}: CapacityValuesProps) {
  const classNames = useStyles();

  const sameMinMax = min === max;
  const sameMaxDesired = max === desired;
  const sameMinDesired = min === desired;
  const sameMinDesiredMax = sameMaxDesired && sameMinDesired;
  return (
    <div className={classNames.minAndMax}>
      <div
        className={classNames.flex}
        style={{
          bottom: `calc(${(max * 100) / maxLimit}% - 16px)`
        }}
      >
        <Typography className={classNames.label}>
          {sameMinDesiredMax
            ? "Max, Desired, Min"
            : sameMaxDesired
            ? "Max, Desired"
            : sameMinMax
            ? "Max, Min"
            : "Max"}
        </Typography>
        <Typography className={classNames.number}>{max}</Typography>
      </div>
      {!sameMinDesiredMax && !sameMaxDesired && (
        <div
          className={classNames.flex}
          style={{
            bottom: `calc(${(desired * 100) / maxLimit}% - 16px)`
          }}
        >
          <Typography className={classNames.label}>
            {sameMinDesired ? "Desired, Min" : "Desired"}
          </Typography>
          <Typography className={classNames.number}>{desired}</Typography>
        </div>
      )}
      {!sameMinDesiredMax && !sameMinDesired && (
        <div
          className={classNames.flex}
          style={{
            bottom: `calc(${(min * 100) / maxLimit}% - 16px)`
          }}
        >
          <Typography className={classNames.label}>Min</Typography>
          <Typography className={classNames.number}>{min}</Typography>
        </div>
      )}
    </div>
  );
}

import { makeStyles } from "@material-ui/core/styles";

//@ts-ignore
export const useStyles = makeStyles(theme => ({
  topicInner: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    textTransform: "none",
    padding: theme.spacing(1),
    flexDirection: "column",
    width: "100%"
  },
  disabledSpan: {
    display: "flex",
    height: 196,
    width: 370
  },
  progress: {
    position: "absolute",
    left: 25
  },
  link: {
    display: "inline-flex",
    textDecoration: "none",
    height: 196,
    width: 370,
    borderBottom: "solid 1px #fff"
  },
  disabledLink: {
    pointerEvents: "none",
    textDecoration: "none",
    display: "inline-flex",
    height: 196,
    width: 370,
    borderBottom: "solid 1px #fff",
    backgroundColor: theme.palette.grey[500],
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  button: {
    display: "flex",
    alignItems: "flex-start",
    "&>span": {
      height: "100%"
    }
  },
  text: {
    textAlign: "left",
    color: "#fff",
    width: "100%",
    fontWeight: "bold",
    fontSize: "2rem"
  },
  left: {
    display: "flex",
    alignItems: "center"
  },

  flex: {
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
    width: "100%"
  },
  marginBottom: {
    marginBottom: 8
  }
}));

import {
  tooltipFormatter,
  valueFormatter
} from "components/grid/dateFormatter";
import { notNullOrUndefined } from "utils/typescript";
import {
  QueryCalculationSchema_cloudPlatform_useCases_calculation,
  QueryCalculationSchema_cloudPlatform_useCases_calculation_inputData_tableSet_tables_schema as InputDataSchema,
  QueryCalculationSchema_cloudPlatform_useCases_calculation_resultData_tableSet_tables_schema as ResultDataSchema
} from "./hooks/schema/QueryCalculationSchema";

const dateFormatter = ({ value }: { value: string }) => valueFormatter(value);

const tooltip = ({ value }: { value: string }) => tooltipFormatter(value);

export const extractCalculationData = (
  calculationData: QueryCalculationSchema_cloudPlatform_useCases_calculation
) => {
  const {
    detail: { state, error: calculationError },
    inputData: { address: inputDataAddress, tableSet: inputTableSet },
    resultData
  } = calculationData;

  const inputSchema = inputTableSet.tables
    .filter(notNullOrUndefined)
    .map(t => t.schema);
  const outputSchema = resultData?.tableSet.tables
    .filter(notNullOrUndefined)
    .map(t => t.schema);

  const columnDefs = (
    schemaName: string,
    inputSchema?: (InputDataSchema | ResultDataSchema)[]
  ) => {
    const schema = inputSchema?.find(schema => schema.name === schemaName);
    return schema?.columns.map(({ name, typeName }) => {
      return {
        field: name,
        headerTooltip: name,
        valueFormatter: typeName === "dateTime" ? dateFormatter : undefined,
        tooltip: typeName === "dateTime" ? tooltip : undefined
      };
    });
  };

  return {
    state,
    inputDataAddress,
    inputSchema,
    resultDataAddress: resultData?.address,
    outputSchema,
    calculationError,
    inputColumnDefs: (schemaName: string) =>
      columnDefs(schemaName, inputSchema),
    outputColumnDefs: (schemaName: string) =>
      columnDefs(schemaName, outputSchema)
  };
};

import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  StreamingSessions,
  StreamingSessionsVariables
} from "./schema/StreamingSessions";

export const sessionsList = gql`
  query StreamingSessions($workspaceId: ID!) {
    projects {
      workspace(workspaceId: $workspaceId) {
        id
        streamingSessions {
          user {
            name
            firstName
            lastName
          }
          isConnected
          startTime
        }
      }
    }
  }
`;

export const useSessionsDataWithPolling = (
  variables: StreamingSessionsVariables
) =>
  useQuery<StreamingSessions, StreamingSessionsVariables>(sessionsList, {
    variables,
    notifyOnNetworkStatusChange: true,
    pollInterval: 5000
  });

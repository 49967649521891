import { makeStyles } from "@material-ui/core";
import { ICellRendererParams } from "ag-grid-community";
import { ApolloError } from "apollo-client";
import { ErrorBox } from "components/error";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 2)
  }
}));
const CustomNoRowsOverlayWithError = (
  props: ICellRendererParams & { error?: ApolloError | ApolloError[] }
) => {
  const { error } = props;
  const classNames = useStyles();
  return (
    <div className={classNames.root}>
      {error ? <ErrorBox apolloError={error} /> : <span>No rows to show</span>}
    </div>
  );
};

export default CustomNoRowsOverlayWithError;

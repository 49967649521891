import {
  AgGridEvent,
  ColDef,
  RowDoubleClickedEvent,
  SelectionChangedEvent
} from "ag-grid-community";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import FullSize from "components/base/FullSize";
import "components/grid/ag-grid-no-border-focus.css";
import { tooltipFormatter } from "components/grid/dateFormatter";
import { useHistory } from "react-router-dom";
import {
  BytesCellRenderer,
  DateCellRenderer
} from "../../components/grid/CellRenderers";
import { dateComparator } from "../../components/grid/dateComparator";
import UploadedDataToolsPanel from "./topics/calculate/UploadedDataToolsPanel";

type UseCaseDataDetail = {
  /**
   * User-defined and system-defined object meta data. More details on https: // docs.aws.amazon.com/en_en/AmazonS3/latest/dev/UsingMetadata.html#object-metadata
   */
  metaData: {
    /**
     * Creator of the object. Uses specific user defined attribute which is also available in 'UserDefined' collection.
     */
    creator: string | null;
    /**
     * Display name of the object. Uses specific user defined attribute which is also available in 'UserDefined' collection.
     */
    displayName: string | null;
  };
  /**
   * The key of the object.
   */
  key: string;
  /**
   * The date and time the object was last modified. The date retrieved from S3 is
   * in ISO8601 format. A GMT formatted date is passed back to the user.
   */
  lastModified: DateTime;
  /**
   * Size in bytes.
   */
  size: Long;
};

type UseCaseData = {
  detail: UseCaseDataDetail | null;
};

type UseCaseDataSelectionGridProps<T extends UseCaseData> = {
  onSelectionChanged: (selection: T) => void;
  onRowDoubleClicked?: (e: RowDoubleClickedEvent) => void;
  dataId?: string;
  scheduleCalculation?: (row: any) => void;
  inApiTester?: boolean;
  rowData: UseCaseData[];
};

// A generic ag-grid implementation which can handle all data extending the here
// defined UseCaseDataDetail. The grid features a single row selection with an
// generic selection event and is styled to only highlight the selected row with
// no column focus highlight.
// Note: By moving columnDefs out of the component to its params this grid could
// be made a completely gerneric selection grid.
function UseCaseDataSelectionGrid<T extends UseCaseData = UseCaseData>({
  onSelectionChanged,
  onRowDoubleClicked,
  dataId,
  scheduleCalculation,
  inApiTester,
  rowData
}: UseCaseDataSelectionGridProps<T>) {
  const history = useHistory();
  const onGridReady = (params: AgGridEvent) => {
    params.api.sizeColumnsToFit();
  };
  const selectRow = (params: AgGridEvent) => {
    if (dataId) {
      params.api.forEachNode(node => {
        if (node.data) {
          node.setSelected(
            node.data.detail.key.split("/").slice(-1)[0] === dataId
          );
        }
      });
    } else {
      if (!inApiTester) {
        params.api.forEachNode(node => {
          node.setSelected(node.rowIndex === 0);
        });
      }
    }
  };
  const onSelectionHandler = (params: SelectionChangedEvent) => {
    const selectedRows = params.api.getSelectedRows();
    onSelectionChanged(selectedRows[0]);
  };

  const columnDefs: ColDef[] = [
    {
      headerName: "Name",
      field: "detail.metaData.displayName",
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true }
    },
    {
      headerName: "Uploaded",
      field: "detail.lastModified",
      cellRendererFramework: DateCellRenderer,
      tooltip: ({ data }) => {
        return tooltipFormatter(data.detail.lastModified);
      },
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: dateComparator,
        browserDatePicker: true,
        filterParams: { suppressAndOrCondition: true }
      }
    },
    {
      headerName: "User",
      field: "detail.metaData.creator",
      filter: "agSetColumnFilter"
    },
    {
      headerName: "Size",
      field: "detail.size",
      cellRendererFramework: BytesCellRenderer,
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true }
    }
  ];
  const frameworksComponents = scheduleCalculation && {
    uploadedDataToolPanel: UploadedDataToolsPanel as any
  };
  const sideBar = scheduleCalculation && {
    toolPanels: [
      {
        id: "uploadedDataTools",
        labelDefault: "Uploaded Data Tools",
        labelKey: "uploadedDataTools",
        toolPanel: "uploadedDataToolPanel",
        iconKey: "uploadedDataTools",
        toolPanelParams: {
          history,
          scheduleCalculation
        }
      }
    ]
  };
  return (
    <FullSize id="data-grid" className="ag-theme-balham">
      <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={{
          cellClass: "no-border"
        }}
        singleClickEdit={true}
        onGridReady={onGridReady}
        rowData={rowData}
        //@ts-ignore -> AG-2080 no ts definition in v19.0.0
        onModelUpdated={selectRow}
        onRowDoubleClicked={onRowDoubleClicked}
        floatingFilter={true}
        rowSelection="single"
        onSelectionChanged={onSelectionHandler}
        enableColResize
        frameworkComponents={frameworksComponents}
        sideBar={sideBar}
      />
    </FullSize>
  );
}

export default UseCaseDataSelectionGrid;

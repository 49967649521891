import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Permissions } from "./schema/Permissions";

export const userPermissionsQuery = gql`
  query Permissions {
    accessManagement {
      me {
        name
        permissions
      }
    }
  }
`;
export default function usePermissionsData() {
  return useQuery<Permissions>(userPermissionsQuery, {
    fetchPolicy:
      /** Permissions will be loaded once when app is loaded.
       *  User has to reload the application - for example, by pressing the F5 key - to retrieve updated permissions.
       * */
      "cache-first"
  });
}

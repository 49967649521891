import {
  Button,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography
} from "@material-ui/core";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import "components/grid/gridToolPanel.css";
import { Component, Fragment } from "react";
import { createRoute } from "utils/url";
import DeleteUseCaseCard from "./DeleteUseCaseCard";
import { UseCaseManagementOverview_cloudPlatform_useCases } from "./hooks/schema/UseCaseManagementOverview";

interface AgGridReactWithHistory extends AgGridReact {
  history: any;
  deleteUseCase: any;
}

export default class UseCaseToolsPanel extends Component<
  AgGridReactWithHistory,
  {
    row: UseCaseManagementOverview_cloudPlatform_useCases | null;
    showDeleteCard: boolean;
  }
> {
  constructor(props: AgGridReactWithHistory) {
    super(props);
    this.state = { row: null, showDeleteCard: false };

    props.api.addEventListener(
      "selectionChanged",
      this.updateSelectedRow.bind(this)
    );

    // event not fired, ag-version might not yet support it
    props.api.addEventListener(
      "toolPanelVisibleChanged",
      this.updateToolPanelVisibleChanged.bind(this)
    );
  }

  updateSelectedRow() {
    var [row] = this.props.api.getSelectedRows();

    if (row) {
      this.props.api.openToolPanel("useCaseTools");
      this.setState({ row });
      this.setState({ showDeleteCard: false });
    } else {
      this.props.api.closeToolPanel();
      this.setState({ row: null });
    }
  }

  updateToolPanelVisibleChanged() {
    if (!this.props.api.isToolPanelShowing()) {
      this.props.api.clearRangeSelection();
    }
  }

  render() {
    return (
      <Paper className="root">
        {this.state.row == null ? (
          <Typography variant="subtitle1">Select a use case</Typography>
        ) : (
          <Fragment>
            <div className="flex">
              <Typography className="title">DETAILS</Typography>

              <Button
                data-testid="open-use-case-button"
                size="small"
                variant="contained"
                className="button"
                onClick={() => {
                  this.props.history.push(
                    createRoute(
                      `/use-cases/${this.state.row!.detail.key}/${
                        this.state.row!.detail.version
                      }`
                    )
                  );
                }}
              >
                Open Use Case
              </Button>
            </div>
            <Divider />
            <List component="nav" className="list">
              <ListItem>
                <ListItemText
                  primary="Key"
                  secondary={this.state.row?.detail.key}
                  data-testid="key"
                  classes={{
                    primary: "primary",
                    secondary: "secondary"
                  }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  secondary={this.state.row?.detail.name}
                  primary="Name"
                  autoCapitalize="characters"
                  classes={{
                    primary: "primary",
                    secondary: "secondary"
                  }}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Version"
                  secondary={this.state.row?.detail.version}
                  classes={{
                    primary: "primary",
                    secondary: "secondary"
                  }}
                />
              </ListItem>
              {this.state.row?.detail.tags.length > 0 && (
                <ListItem>
                  <ListItemText
                    className="tags"
                    primary="Tags"
                    secondary={
                      <Typography component="span">
                        {this.state.row?.detail.tags.map(tag => (
                          <Chip
                            label={tag}
                            variant="outlined"
                            key={tag}
                            classes={{
                              root: "chip",
                              outlined: "outlined"
                            }}
                          />
                        ))}
                      </Typography>
                    }
                    classes={{
                      primary: "primary"
                    }}
                  />
                </ListItem>
              )}
              {this.state.row?.detail.description && (
                <ListItem>
                  <ListItemText
                    className="description"
                    secondary={this.state.row?.detail.description}
                    primary="Description"
                    classes={{
                      primary: "primary",
                      secondary: "secondary"
                    }}
                  />
                </ListItem>
              )}
            </List>
            <Divider />
            <div className="button-root">
              <Button
                data-testid="create-new-version-button"
                size="small"
                variant="contained"
                className="button"
                onClick={() => {
                  this.props.history.push(
                    createRoute(
                      `/use-case-manager/use-case/${
                        this.state.row!.detail.key
                      }@${this.state.row!.detail.version}/create`
                    )
                  );
                }}
              >
                Add new version
              </Button>
              <Button
                size="small"
                variant="contained"
                className="button"
                onClick={() => this.setState({ showDeleteCard: true })}
              >
                Delete use case
              </Button>
            </div>
            {this.state.showDeleteCard ? (
              <DeleteUseCaseCard
                onClose={() => this.setState({ showDeleteCard: false })}
                onDelete={() => {
                  this.setState({ showDeleteCard: false });
                  this.props.deleteUseCase(
                    this.state.row!.detail.key,
                    this.props.api
                  );
                }}
              />
            ) : null}
          </Fragment>
        )}
      </Paper>
    );
  }
}

import {
  IconButton,
  makeStyles,
  SvgIconTypeMap,
  Tooltip,
  Typography
} from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import Link from "components/base/Link";

const useStyles = makeStyles(theme => ({
  noBorderRadius: {
    borderRadius: 0
  },
  link: {
    textDecoration: "none"
  }
}));

export default function LinkIconButton({
  to,
  title,
  testId,
  icon: Icon
}: {
  to: string;
  title: string;
  testId?: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}) {
  const classNames = useStyles();
  return (
    <Link className={classNames.link} to={to}>
      <Tooltip title={<Typography>{title}</Typography>}>
        <IconButton className={classNames.noBorderRadius} color="secondary">
          <Icon data-testid={testId} />
        </IconButton>
      </Tooltip>
    </Link>
  );
}

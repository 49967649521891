import { ThemeProvider } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { LicenseManager } from "ag-grid-enterprise/main";
import { authenticatorSetup, Shell } from "features/shell";
import { createBrowserHistory } from "history";
import "index.css";
import { ModelProvider } from "model";
import getRoutes from "pages/Routes";
import { Fragment } from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import * as serviceWorker from "serviceWorker";
import "typeface-roboto";
import { assertConfigSetup, printConfigSetup } from "utils/config";
import theme from "utils/theme";

// todo: LPS-7379 make this a ts file and check typings

LicenseManager.setLicenseKey(
  "LOCOM_Software_GmbH_MultiApp_1Devs7_February_2019__MTU0OTQ5NzYwMDAwMA==7a9e3ff29fc198fad76d018b27fa0dda"
);

printConfigSetup();
assertConfigSetup();
authenticatorSetup();

const history = createBrowserHistory();

ReactDOM.render(
  <Router history={history}>
    <ThemeProvider theme={theme}>
      <ModelProvider history={history}>
        <Fragment>
          <CssBaseline />
          <Shell routes={getRoutes()} history={history} />
        </Fragment>
      </ModelProvider>
    </ThemeProvider>
  </Router>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

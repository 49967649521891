import { LinearProgress } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import { ColDef, GridApi } from "ag-grid-community";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { ApolloError } from "apollo-client";
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import CustomNoRowsOverlayWithError from "./CustomNoRowsOverlayWithError";
import "./grid.css";
import { createColumns, RenderableColumnDefinition } from "./gridTypings";

/**
 *  @interface GridProps<TItem, TColDef>
 *  @argument TItem type of a grid item
 *  @argument TColDef type of modified ag grid column definition
 */
export interface GridProps<TItem, TColDef> {
  /** Rows with data objects (e.g `[{col1: "value1", col2: 7}]`). Used to infer cell renderer input typings. */
  rowData?: TItem[];

  /** Column definitions each consisting of:
   *
   *    @param colDef an ag-grid column definition without `cellRenderer`
   *    @param renderer a typed version of `cellRenderer` with following props:
   *      - `data` prop gives typed access to rendered data item.
   *      - `value` property is not available, extract correct `value` from `data` props manually.
   *      - `cellRendererParams` if enabled. To enable the `cellRendererParams` wrap the column inside `createColumn` function and provide `T` of `Grid<T>` manually.
   */
  columns: RenderableColumnDefinition<TColDef, TItem>[];

  /** Indicate that the grid is loading either on the first or on a subsequent reload */
  isLoading?: boolean;

  /** Height to be used for each row. */
  rowHeight?: number;

  /** Error while loading rowData. */
  error?: ApolloError | ApolloError[];
}

export const TestIds = {
  loadingIndicator: "loadingIndicator",
  skeleton: "skeleton",
  gridContent: "agGrid"
};

const useStyles = makeStyles(theme => ({
  skeletonText: {
    width: 200
  },
  skeletonRect: {
    width: "100%",
    height: 500
  },
  progress: {
    marginTop: -4
  },
  grid: { height: "100%" }
}));

/** 
    Grid component build on AG-Grid. 

    Enriches AG-Grid with default behavior, theme and typed cell renderers.
  */
const Grid = <TItem extends {}>({
  rowData,
  columns,
  isLoading = false,
  rowHeight = 60,
  error
}: GridProps<TItem, ColDef>) => {
  const classes = useStyles();
  const { columns: agColumnDefs, frameworkComponents } =
    createColumns<TItem>(columns);
  const [gridApi, setGridApi] = useState<GridApi>();
  const noRowsOverlayComponentParams = useMemo(() => {
    return {
      error
    };
  }, [error]);

  const noRowsOverlayComponent = useMemo(() => {
    return CustomNoRowsOverlayWithError;
  }, []);

  useEffect(() => {
    if (gridApi && error) {
      gridApi.showNoRowsOverlay();
    }
  }, [gridApi, error]);

  return (
    <>
      {isLoading && (
        <LinearProgress
          className={classes.progress}
          data-testid={TestIds.loadingIndicator}
        />
      )}
      {rowData === undefined ? (
        <Skeleton
          data-testid={TestIds.skeleton}
          variant="rect"
          className={classes.skeletonRect}
        />
      ) : (
        <div
          data-testid={TestIds.gridContent}
          className={clsx("ag-theme-balham", classes.grid)}
        >
          <AgGridReact
            rowData={rowData}
            columnDefs={agColumnDefs}
            frameworkComponents={frameworkComponents}
            rowHeight={rowHeight}
            enableFilter
            enableSorting
            suppressAutoSize
            suppressColumnVirtualisation
            onGridReady={params => {
              params.api.sizeColumnsToFit();
              setGridApi(params.api);
            }}
            onRowDataChanged={params => {
              params.api.sizeColumnsToFit();
            }}
            onModelUpdated={params => {
              params.api.sizeColumnsToFit();
            }}
            onGridSizeChanged={params => {
              params.api.sizeColumnsToFit();
            }}
            noRowsOverlayComponentFramework={noRowsOverlayComponent}
            //@ts-ignore
            noRowsOverlayComponentParams={noRowsOverlayComponentParams}
          ></AgGridReact>
        </div>
      )}
    </>
  );
};

export default Grid;

import { Button, makeStyles, Typography } from "@material-ui/core";
import { Error } from "@material-ui/icons";
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    margin: theme.spacing(0, 3),
    backgroundColor: "#f5f7f7",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.error.main
  },
  title: { marginTop: theme.spacing(2), fontWeight: "bold" },
  message: { color: "#999", textAlign: "center", margin: theme.spacing(2, 0) },
  button: {
    color: "#FFFFFF",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8
    }
  }
}));
export default function ErrorPage({
  title,
  message,
  redirect,
  handelClick,
  buttonTitle
}: {
  title: string;
  message: string;
  redirect?: boolean;
  handelClick?: () => void;
  buttonTitle?: string;
}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Error className={classes.icon} />

      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.message}>{message}</Typography>
      {redirect ? (
        <div>
          <Button
            onClick={handelClick}
            variant="contained"
            className={classes.button}
          >
            {buttonTitle}
          </Button>
        </div>
      ) : null}
    </div>
  );
}

import { makeStyles } from "@material-ui/core/styles";
import { Fragment } from "react";

const useStyles = makeStyles(theme => ({
  text: {
    color: "#788791"
  }
}));

export default function CalculationNotExist() {
  const { text } = useStyles();

  return (
    <Fragment>
      <h1 className={text}> There's nothing here. </h1>
      <span className={text}>
        Your link may be broken or the calculation may have been removed.
      </span>
    </Fragment>
  );
}

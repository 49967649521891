import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import clsx from "clsx";
import NavLink, { LinkProps } from "components/base/Link";
import {
  AtLeastOnePermission,
  RestrictedRealm
} from "features/access-management";
import React from "react";

const useStyles = makeStyles(theme => ({
  root: {
    width: 350,
    height: 380
  },
  content: {
    color: theme.palette.text.primary,
    height: 127,
    borderBottom: "solid 1px #e5e5e5"
  },
  name: { fontWeight: "bold" },
  description: {
    marginTop: 12
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  link: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    textDecoration: "none",
    height: "100%"
  },
  cardActions: {
    padding: theme.spacing(2)
  },
  grayscale: {
    filter: "grayscale(100%)"
  },
  graytext: {
    color: theme.palette.grey[600]
  }
}));

const NoTooltip: React.FC<TooltipProps> = ({ children }) => <>{children}</>;
const NoLink: React.FC<Partial<LinkProps>> = ({ children }) => <>{children}</>;

export default function SpaceCard({
  name,
  description,
  targetRoles = [],
  tooltip,
  link,
  testid,
  image,
  requiredPermissions
}: {
  name: string;
  description: string;
  targetRoles: string[];
  tooltip?: React.ReactNode;
  link?: LinkProps["to"];
  testid?: string;
  image: string;
  requiredPermissions: AtLeastOnePermission;
}) {
  const classes = useStyles();
  const Tooltip = tooltip ? MaterialTooltip : NoTooltip;

  return (
    <RestrictedRealm requiredPermissions={requiredPermissions}>
      {({ accessGranted }) => {
        const Link = link && accessGranted ? NavLink : NoLink;

        return (
          <Tooltip title={tooltip!}>
            <Card className={classes.root}>
              <Link to={link!} className={classes.link}>
                <CardMedia
                  className={clsx(classes.media, {
                    [classes.grayscale]: !accessGranted
                  })}
                  image={image}
                />
                <CardContent
                  className={clsx(classes.content, {
                    [classes.graytext]: !accessGranted
                  })}
                  data-testid={accessGranted ? testid : null}
                >
                  <Typography className={classes.name}>{name}</Typography>
                  <Typography
                    className={classes.description}
                    variant="body2"
                    component="p"
                  >
                    {description}
                  </Typography>
                </CardContent>
                <CardActions className={classes.cardActions}>
                  {targetRoles.map(role => (
                    <Chip
                      label={role}
                      key={role}
                      variant="outlined"
                      size="small"
                    />
                  ))}
                </CardActions>
              </Link>
            </Card>
          </Tooltip>
        );
      }}
    </RestrictedRealm>
  );
}

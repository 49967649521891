import { IconButton, Tooltip, Typography } from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  AccessTime,
  CheckCircleOutline,
  DonutLarge,
  ErrorOutline,
  FilterList,
  SelectAll
} from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  menu: { top: "52px !important", left: "-45px !important" },
  noBorderRadius: {
    borderRadius: 0
  },
  iconColor: {
    color: theme.palette.secondary.main
  },
  menuItem: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white
      }
    }
  }
}));

interface ProjectStateFilterProps {
  onStateFilterChanged: (state: string) => void;
}

export default function ProjectStateFilter({
  onStateFilterChanged
}: ProjectStateFilterProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const FilterByProjectState = (value: any) => {
    const searchValue = value === "All" ? "" : value;
    onStateFilterChanged(searchValue);
    handleClose();
  };
  const stateTextIcon = [
    { state: "All", icon: <SelectAll fontSize="small" /> },
    { state: "Scheduled", icon: <AccessTime fontSize="small" /> },
    { state: "InProgress", icon: <DonutLarge fontSize="small" /> },
    { state: "Created", icon: <CheckCircleOutline fontSize="small" /> },
    { state: "Failed", icon: <ErrorOutline fontSize="small" /> }
  ];
  return (
    <div>
      <Tooltip title={<Typography>Filter by project's state</Typography>}>
        <IconButton onClick={handleClick} className={classes.noBorderRadius}>
          <FilterList className={classes.iconColor} />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu"
        className={classes.menu}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {stateTextIcon.map(({ icon, state }) => (
          <MenuItem
            key={state}
            className={classes.menuItem}
            onClick={() => FilterByProjectState(state)}
          >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={state} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

import { BarChart, TableChart } from "@material-ui/icons";
import { Charts } from "components/chart";
import { observer } from "mobx-react-lite";
import { useRef } from "react";
import { Panel, PanelList, Tab, TabList, Tabs } from "react-tabtab";
import { getConfigValue } from "utils/config";
import Header from "./Header";
import customStyle from "./reactTabStyle";
import TableSetGrid from "./TableSetGrid";
import { useStyles } from "./tableSetStyle";

const TableSet = observer(
  ({
    tablesSchema,
    columnDefs,
    dataKey,
    calculationId,
    useCaseKey,
    useCaseVersion = null,
    loadData,
    dataAddress,
    chartSources,
    dataType,
    toCalculationResultTable,
    toResultChart,
    backgroundColor
  }) => {
    const { tabsRoot, flexContainer, tableView, icon, chartsContainer } =
      useStyles({});
    const TableSetGridReference = useRef();
    const autoSize = () => {
      const columnsIds = TableSetGridReference.current
        .grid()
        .columnApi.columnController.allDisplayedColumns.map(
          ({ colId }) => colId
        );

      TableSetGridReference.current
        .grid()
        .columnApi.columnController.autoSizeColumns(columnsIds);
    };
    const sizeToFit = () => {
      TableSetGridReference.current.grid().api.sizeColumnsToFit();
    };
    const exploreData = dataAddress => {
      window.open(
        `${getConfigValue(
          "TABLESET_VISUALIZER_URL"
        )}?dataSource=${dataAddress}#/analyse/`,
        "_blank"
      );
    };
    return (
      <div className={tabsRoot} data-testid="tables-charts">
        <Tabs customStyle={customStyle} showModalButton={false}>
          <PanelList>
            {tablesSchema.map(({ name }) => (
              <Panel key={name}>
                <div className={flexContainer}>
                  <div key={name} className={tableView}>
                    <Header
                      name={name}
                      resizeButtons={true}
                      tableDeepLinks={true}
                      autoSize={autoSize}
                      dataType={dataType}
                      sizeToFit={sizeToFit}
                      toCalculationResultTable={
                        toCalculationResultTable &&
                        toCalculationResultTable(name)
                      }
                      exploreData={() => exploreData(dataAddress)}
                      backgroundColor={backgroundColor}
                    />
                    <TableSetGrid
                      key={name}
                      dataKey={dataKey}
                      columnDefs={columnDefs(name)}
                      name={name}
                      useCaseKey={useCaseKey}
                      useCaseVersion={useCaseVersion}
                      calculationId={calculationId}
                      loadData={loadData}
                      ref={TableSetGridReference}
                    />
                  </div>
                </div>
              </Panel>
            ))}
            <Panel>
              <div className={flexContainer}>
                <div key="Charts" className={tableView}>
                  <Header
                    name="Charts"
                    chartDeepLinks
                    dataType={dataType}
                    toResultChart={toResultChart && toResultChart()}
                    exploreData={() => exploreData(dataAddress)}
                    backgroundColor={backgroundColor}
                  />
                  <div className={chartsContainer}>
                    <Charts
                      dataSource={dataAddress}
                      chartSources={chartSources}
                      useCaseKey={useCaseKey}
                      useCaseVersion={useCaseVersion}
                      calculationId={calculationId}
                    />
                  </div>
                </div>
              </div>
            </Panel>
          </PanelList>
          <TabList>
            {tablesSchema.concat([{ name: "Charts" }]).map(({ name }) => (
              <Tab key={name}>
                {name}
                {name === "Charts" ? (
                  <BarChart fontSize="small" className={icon} />
                ) : (
                  <TableChart fontSize="small" className={icon} />
                )}
              </Tab>
            ))}
          </TabList>
        </Tabs>
      </div>
    );
  }
);

export default TableSet;

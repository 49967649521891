import { MutationFunctionOptions } from "@apollo/react-common";
import {
  MutationHookOptions,
  MutationTuple,
  useMutation
} from "@apollo/react-hooks";
import { ApolloError, OperationVariables } from "apollo-client";

import { DocumentNode } from "graphql";
import { useState } from "react";

interface MutationResult<TData> {
  loading: boolean;
  data?: TData;
  error?: ApolloError;
}

interface State<TData>
  extends Pick<MutationResult<TData>, "loading" | "data" | "error"> {}

function useResettableMutation<TData, TVariables = OperationVariables>(
  mutation: DocumentNode,
  options?: MutationHookOptions<TData, TVariables>
): [
  MutationTuple<TData, TVariables>[0],
  MutationResult<TData> & { reset: () => void }
] {
  const [{ loading, data, error }, setResult] = useState<State<TData>>({
    loading: false
  });
  const onCompleted = (data: TData) => {
    setResult(state => ({ ...state, data, loading: false }));
    options?.onCompleted && options.onCompleted(data);
  };
  const onError = (apolloError: ApolloError) => {
    setResult({ loading: false, error: apolloError });
    options?.onError && options.onError(apolloError);
  };
  const reset = () =>
    setResult(state => ({
      ...state,
      data: undefined,
      error: undefined
    }));

  const [mutate] = useMutation<TData, TVariables>(mutation, {
    ...options,
    onCompleted,
    onError
  });
  const performMutation = async (
    params?: MutationFunctionOptions<TData, TVariables>
  ) => {
    setResult(state => ({
      ...state,
      loading: true
    }));
    return mutate(params);
  };

  return [performMutation, { data, loading, error, reset }];
}
export default useResettableMutation;

import { makeStyles, Typography } from "@material-ui/core";

import { useWorkspaceName } from "./workspaceHooks";

const useStyles = makeStyles(() => ({
  name: { color: "rgba(0,0,0,.2)", fontSize: 12, fontWeight: "bold" }
}));

export default function WorkspaceNameForProject({
  projectId
}: {
  projectId: string;
}) {
  const classes = useStyles();
  const { data: workspace } = useWorkspaceName({
    projectId
  });

  const workspaceName = workspace?.projects.project?.workspace?.name;

  return workspaceName ? (
    <Typography className={classes.name}> {workspaceName}</Typography>
  ) : null;
}

import { Button, Link, TextField } from "@material-ui/core";
import "../authenticator-components.css";

export default function ConfirmationForm({
  handleInputChange,
  resendCode,
  resetPassword
}) {
  return (
    <form className="form" noValidate>
      <div className="formField">
        <TextField
          name="code"
          label="Confirmation Code *"
          autoComplete="one-time-code"
          data-test="code-input"
          className="input"
          variant="outlined"
          onChange={handleInputChange}
        />
      </div>
      <div className="formField">
        <TextField
          name="password"
          label="New Password *"
          type="password"
          autoComplete="new-password"
          data-test="new-password-input"
          className="input"
          variant="outlined"
          onChange={handleInputChange}
        />
      </div>
      <div className="confirmSignUpFooter">
        <Link
          data-testid="resend-code"
          className="link"
          color="primary"
          onClick={resendCode}
        >
          Resend code
        </Link>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="button"
          data-test="reset-password-button"
          onClick={resetPassword}
        >
          SUBMIT
        </Button>
      </div>
    </form>
  );
}

import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  QueryApiDetail,
  QueryApiDetailVariables
} from "./schema/QueryApiDetail";
import { QueryApiKeys } from "./schema/QueryApiKeys";

export const apiDetail = gql`
  query QueryApiDetail($useCaseKey: String!, $useCaseVersion: String) {
    cloudPlatform {
      useCase(useCaseKey: $useCaseKey, version: $useCaseVersion) {
        publicApi {
          sudoApiKey
          url
          userApiKey
          apiCalls
        }
      }
    }
  }
`;

export const useQueryApiDetail = (variables: QueryApiDetailVariables) =>
  useQuery<QueryApiDetail, QueryApiDetailVariables>(apiDetail, { variables });

export const queryApiKeys = gql`
  query QueryApiKeys {
    cloudPlatform {
      publicApi {
        apiKeys {
          description
          enabled
          id
          name
          createdDate
          lastUpdatedDate
        }
      }
    }
  }
`;

export const useQueryApiKeys = () => useQuery<QueryApiKeys>(queryApiKeys);

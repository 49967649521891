import { ColDef } from "ag-grid-community";

export type RenderableColumnDefinition<
  TColDef extends Omit<ColDef, "cellRenderer">,
  TData
> = {
  /**
   * Ag-grid column definition without `cellRenderer`
   * @link https://www.ag-grid.com/react-data-grid/column-definitions/
   */
  colDef: TColDef;
  /**
   *  @param data prop gives typed access to rendered data item.
   *  @param value property is NOT available, extract correct `value` from `data` props manually.
   *  @param ContentOfCellRendererParams if enabled. To enable the `cellRendererParams` wrap the column inside `createColumn` function and provide `T` of `Grid<T>` manually.
   */
  render: (
    args: TColDef extends { cellRendererParams: infer A }
      ? A & { data: TData }
      : { data: TData }
  ) => React.ReactNode;
};

/** Can be used to wrap a Grid column to make use of `cellRenderParams` from within `render` props.
 *  To make it work you might also need to define Grid<`T`> manually.
 */
export function createColumn<
  TColDef extends Omit<ColDef, "cellRenderer">,
  TData
>(
  column: RenderableColumnDefinition<TColDef, TData>
): RenderableColumnDefinition<TColDef, TData> {
  return column;
}

export function createColumns<TData>(
  renderableColumns: RenderableColumnDefinition<ColDef, TData>[]
) {
  const columns = renderableColumns.map(({ colDef }, index) => ({
    ...colDef,
    cellRenderer: index.toString()
  }));
  const frameworkComponents: Record<
    number,
    new () => any
  > = renderableColumns.reduce((prev, current, index) => {
    return {
      ...prev,
      [index]: current.render
    };
  }, {});

  return {
    columns,
    frameworkComponents
  };
}

import {
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Error, Refresh } from "@material-ui/icons";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { ErrorBox } from "components/error";
import {
  DateTickerCellRenderer,
  DisabledGroupCellRenderer
} from "components/grid/CellRenderers";
import { dateComparator } from "components/grid/dateComparator";
import { tooltipFormatter } from "components/grid/dateFormatter";
import LinearProgressWithLabel from "components/progress/LinearProgressWithLabel";
import { formatDistanceStrict } from "date-fns";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useSearchQueryParam } from "utils/url";
import {
  useQueryUseCaseCalculations,
  useQueryUseCaseJobsCount
} from "./hooks/AllCalculationsResultsHooks";
import NoCalculationsFound from "./NoCalculationsFound";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "solid 1px #dedede",
    marginBottom: theme.spacing(2)
  },
  title: {
    borderBottom: `solid 3px ${theme.palette.primary.main}`,
    display: "inline-block",
    color: "#788791",
    marginBottom: 0
  },
  flex: { display: "flex", alignItems: "center" },
  errorIcon: {
    marginLeft: theme.spacing(1)
  },
  dialogContent: {
    overflow: "hidden",
    padding: 0,
    "&:first-child": {
      paddingTop: 0
    }
  },
  customWidth: {
    maxWidth: "none",
    minWidth: 400
  }
}));

const UseCaseCalculationsResults = ({
  signedInUser,
  useCaseKey,
  useCaseVersion,
  navigateToCalculate,
  navigateToCalculation
}) => {
  const {
    root,
    title,
    header,

    flex,
    errorIcon,
    dialogContent,
    customWidth
  } = useStyles({});
  const [api, setApi] = useState(null);
  const [calculationId, setCalculationId] =
    useSearchQueryParam("calculationId");
  const [open, setOpen] = React.useState(false);

  const handleClick = value => {
    setOpen(value);
  };

  const { orderedJobs, progressValue, loading, reloadJobs, errors } =
    useQueryUseCaseCalculations(useCaseKey, useCaseVersion, null, null, 100);

  const { data: jobsCount } = useQueryUseCaseJobsCount({
    useCaseKey,
    useCaseVersion,
    scheduler: null
  });
  const filterByCurrentUser = useCallback(
    api => {
      const hasCalculations =
        orderedJobs && orderedJobs.find(row => row.username === signedInUser);
      const myCalculationsInstance = api.getFilterInstance("username");
      myCalculationsInstance.selectNothing();

      if (hasCalculations) {
        myCalculationsInstance.selectValue(signedInUser);
      } else {
        const allCalculationsInstance = api.getFilterInstance("username");
        allCalculationsInstance.selectEverything();
      }
      api.onFilterChanged();
    },
    [orderedJobs, signedInUser]
  );

  const selectScheduledCalculation = useCallback(
    api => {
      if (calculationId) {
        api.forEachNode(node => {
          node.setSelected(node.data.id === calculationId);
        });
      } else {
        api.forEachNode(node => {
          node.setSelected(node.rowIndex === 0);
        });
      }
    },
    [calculationId]
  );

  useEffect(() => {
    if (!api || loading) return;
    selectScheduledCalculation(api);
  }, [api, loading, selectScheduledCalculation]);

  const noRowsOverlayComponent = "customNoRowsOverlay";
  const noRowsOverlayComponentParams = {
    useCaseKey,
    navigateToCalculate
  };
  const StateCellRenderer = params => (
    <span data-testid="state">{params.value}</span>
  );
  const frameworkComponents = {
    customNoRowsOverlay: NoCalculationsFound,
    dateRenderer: DateTickerCellRenderer,
    disabledGroupRenderer: DisabledGroupCellRenderer,
    stateCellRenderer: StateCellRenderer
  };

  const onFirstDataRendered = params => {
    params.api.sizeColumnsToFit();
    setApi(params.api);
    filterByCurrentUser(params.api);
  };

  const columnDefs = [
    {
      headerName: "State",
      field: "state",
      filter: "agSetColumnFilter",
      filterParams: { newRowsAction: "keep" },
      cellRenderer: "stateCellRenderer"
    },
    {
      headerName: "Input data",
      field: "displayName",
      filter: "agTextColumnFilter",
      filterParams: { suppressAndOrCondition: true, newRowsAction: "keep" }
    },
    {
      headerName: "User",
      field: "username",
      filter: "agSetColumnFilter",
      filterParams: { newRowsAction: "keep" },
      width: 350
    },
    {
      headerName: "Created",
      field: "created",
      cellRenderer: "dateRenderer",
      filter: "agDateColumnFilter",
      filterParams: {
        comparator: dateComparator,
        browserDatePicker: true,
        suppressAndOrCondition: true,
        newRowsAction: "keep"
      },
      width: 250,
      //tooltipComponent would be possible with ag-grid version 20.1
      tooltip: ({ data }) => tooltipFormatter(data.created)
    },
    {
      headerName: "Started",
      field: "started",

      tooltip: ({ data }) => tooltipFormatter(data.started),
      cellRenderer: params => {
        const started = params.data.started;
        const created = params.data.created;
        return started === null
          ? "pending"
          : `after ${formatDistanceStrict(started, created)}`;
      },
      comparator: dateComparator,
      suppressFilter: true
    },

    {
      headerName: "Completed",
      field: "completed",

      tooltip: ({ data }) => tooltipFormatter(data.completed),
      cellRenderer: params => {
        const completed = params.data.completed;
        const started = params.data.started;
        return completed === null
          ? "pending"
          : `in ${formatDistanceStrict(completed, started)}`;
      },
      comparator: dateComparator,
      suppressFilter: true
    }
  ];
  const onSelectionHandler = params => {
    const selectedRows = params.api.getSelectedRows();
    setCalculationId(selectedRows[0].id);
  };
  const navigateToCalculationResult = id => navigateToCalculation(id);
  const debouncedRowClicked = debounce(navigateToCalculationResult, 500);

  const allCalculationsCount =
    jobsCount?.cloudPlatform.useCase?.calculationsCount;
  const progress = !allCalculationsCount
    ? 0
    : allCalculationsCount === 0
    ? 100
    : (progressValue / allCalculationsCount) * 100;

  const ErrorDialog = () => (
    <div className={flex}>
      <Tooltip title="Errors have occurred while loading the calculations, click this icon to see them.">
        <div>
          <IconButton
            className={errorIcon}
            onClick={e => {
              e.preventDefault();
              handleClick(true);
            }}
          >
            <Error />
          </IconButton>
        </div>
      </Tooltip>
      <Dialog
        open={open}
        onClose={() => {
          handleClick(false);
        }}
        classes={{ paperScrollPaper: customWidth }}
      >
        <DialogContent className={dialogContent}>
          <ErrorBox
            apolloError={errors}
            title="Errors by loading the use case calculations!"
          />
        </DialogContent>
      </Dialog>
    </div>
  );
  return loading ? (
    <LinearProgressWithLabel value={progress} />
  ) : (
    <div className={root}>
      <div className={header}>
        <div className={flex}>
          <Typography
            className={title}
          >{`Calculations for: ${useCaseKey}`}</Typography>
          {errors.length > 0 ? <ErrorDialog /> : null}
        </div>
        <Tooltip title="Reload calculations">
          <div>
            <IconButton onClick={reloadJobs} data-testid="reload-calculations">
              <Refresh />
            </IconButton>
          </div>
        </Tooltip>
      </div>
      <div
        data-testid="result-table"
        id="myGrid"
        style={{
          height: "100%",
          width: "100%"
        }}
        className="ag-theme-balham"
      >
        <AgGridReact
          key={useCaseKey}
          columnDefs={columnDefs}
          rowData={orderedJobs}
          /* cause error due to accessing replaced elements in the mst tree
          deltaRowDataMode={true}  */
          getRowNodeId={({ id }) => id}
          onGridReady={params => setApi(params.api)}
          onFirstDataRendered={onFirstDataRendered}
          frameworkComponents={frameworkComponents}
          floatingFilter={true}
          noRowsOverlayComponent={noRowsOverlayComponent}
          noRowsOverlayComponentParams={noRowsOverlayComponentParams}
          rowSelection="single"
          onSelectionChanged={onSelectionHandler}
          onRowClicked={e => debouncedRowClicked(e.data.id)}
        />
      </div>
    </div>
  );
};

export default UseCaseCalculationsResults;

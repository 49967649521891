import {
  ActionButton,
  Panel,
  Tab,
  TabList
} from "react-tabtab/lib/themes/material-design";
import styled from "styled-components";

const TabListStyle = styled(TabList)`
  box-shadow: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 0px 35px;
  position: relative;
  background-color: #f5f7f7;
  &:before {
    left: 0;
    right: 0;
    height: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    content: "";
    top: auto;
    display: block;
    position: absolute;
  }
  &:after {
    clear: both;
    display: block;
    content: "";
  }
  & > :nth-child(1) {
    & > :first-child {
      border-left: none;
    }
    & > :last-child {
      border-right: none;
    }
  }
  & > div > ul {
    display: flex;
  }
  ${props => (!props.showArrowButton ? `padding:0;` : null)}
`;
const TabStyle = styled(Tab)`
  color: #3aa9ae;
  display: block;
  float: left;
  padding: 10px 10px 5px 10px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  margin-right: -1px;
  position: relative;
  &:hover {
    background-color: transparent;
    color: #3aa9ae;
    border-bottom: none;
  }
  ${props =>
    props.active && !props.vertical
      ? `
      border-top:1px solid #fff
      border-bottom:solid 2px #318CA9;
      font-weight:bold;
      color:#318CA9;
      background-color:#ffffff;
      margin-top:0;
      margin-bottom:0
      &:hover {
        background-color:#ffffff;
        color: #318CA9;
        border-bottom:solid 2px #318CA9;
      }
    `
      : null}
  &>span {
    display: flex;
    align-items: center;
  }
`;

const ActionButtonStyle = styled(ActionButton)`
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  & > svg {
    padding-top: 0;
    color: #3aa9ae;
  }
`;

const PanelStyle = styled(Panel)`
  height: 100%;

  padding: 8px;
  padding-bottom: 16px;
  border: none;
`;

// need to follow this object naming
const reactTabStyle = {
  TabList: TabListStyle,
  ActionButton: ActionButtonStyle,
  Tab: TabStyle,
  Panel: PanelStyle
};

export default reactTabStyle;

import { useMutation } from "@apollo/react-hooks";
import {
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography
} from "@material-ui/core";
import PublishIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import Link from "components/base/Link";
import BasicCard from "components/card/BasicCard";
import { mutationDeleteUseCase } from "./hooks/mutationDeleteUseCase";
import {
  DeleteUseCase,
  DeleteUseCaseVariables
} from "./hooks/schema/DeleteUseCase";
import { queryUseCaseList, UseCaseType } from "./hooks/useQueryUseCaseList";

export const useStyles = makeStyles(theme => ({
  deleteIcon: {
    display: "none",
    pointerEvents: "none",
    padding: 6
  },
  publishIcon: {
    padding: 6
  },
  iconColor: {
    color: theme.palette.primary.main
  }
}));

export default function UseCaseCard({
  useCaseKey,
  tags,
  name,
  image,
  description,
  version
}: UseCaseType) {
  const { deleteIcon, publishIcon, iconColor } = useStyles({});
  const [lazyUseDeleteUseCase, { loading, called }] = useMutation<
    DeleteUseCase,
    DeleteUseCaseVariables
  >(mutationDeleteUseCase, {
    refetchQueries: [{ query: queryUseCaseList }]
  });
  const invalidated = loading || called;

  return (
    <Grid item data-test="use-case-card">
      <BasicCard
        disabled={invalidated}
        name={name}
        icon={image}
        tags={tags}
        linkTo={`./${useCaseKey}/${version}`}
        description={description}
      >
        <div>
          <IconButton
            data-test="delete-use-case"
            className={deleteIcon}
            disabled={invalidated}
            onClick={async event => {
              event.stopPropagation();
              event.preventDefault();
              await lazyUseDeleteUseCase({
                variables: { useCaseKey }
              });
            }}
          >
            <DeleteIcon className={iconColor} />
          </IconButton>
          <Link
            to={`/use-case-manager/use-case/${useCaseKey}@${version}/create`}
          >
            <Tooltip
              placement="left"
              title={<Typography> Publish new version </Typography>}
            >
              <IconButton className={publishIcon}>
                <PublishIcon className={iconColor} />
              </IconButton>
            </Tooltip>
          </Link>
        </div>
      </BasicCard>
    </Grid>
  );
}

import { useQuery } from "@apollo/react-hooks";
import {
  Grid,
  IconButton,
  LinearProgress,
  makeStyles,
  Modal,
  Paper,
  Tooltip,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import UpdateIcon from "@material-ui/icons/Update";
import gql from "graphql-tag";
import { useState } from "react";
import { SchemaDiff, SchemaDiffVariables } from "./schema/SchemaDiff";
import TechnicalChangelog from "./TechnicalChangelog";

const QuerySchemaDiff = gql`
  query SchemaDiff($useCaseKey: String!, $useCaseVersion: String!) {
    cloudPlatform {
      useCase(useCaseKey: $useCaseKey, version: $useCaseVersion) {
        schema {
          input {
            columns {
              nullable
              name
              typeName
            }
            name
          }
          output {
            columns {
              name
              nullable
              typeName
            }
            name
          }
        }
        detail {
          releaseType
          version
          schemaDiff {
            input {
              changedTables {
                name
                changedColumns {
                  from {
                    name
                    nullable
                    typeName
                  }
                  to {
                    name
                    nullable
                    typeName
                  }
                }
                missingColumns {
                  name
                  nullable
                  typeName
                }
                newColumns {
                  name
                  nullable
                  typeName
                }
              }
              missingTables {
                name
                columns {
                  name
                  nullable
                  typeName
                }
              }
              newTables {
                name
                columns {
                  name
                  nullable
                  typeName
                }
              }
            }
            output {
              changedTables {
                name
                changedColumns {
                  from {
                    name
                    nullable
                    typeName
                  }
                  to {
                    name
                    nullable
                    typeName
                  }
                }
                missingColumns {
                  name
                  typeName
                  nullable
                }
                newColumns {
                  name
                  nullable
                  typeName
                }
              }
              missingTables {
                name
                columns {
                  name
                  nullable
                  typeName
                }
              }
              newTables {
                name
                columns {
                  name
                  nullable
                  typeName
                }
              }
            }
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    top: "10vh",
    left: "10vw",
    width: "80vw",
    height: "80vh",
    overflow: "auto",
    padding: theme.spacing(2),
    display: "flex"
  },
  content: {
    paddingTop: theme.spacing(2)
  }
}));

const TechnicalChangelogButton = ({
  useCaseKey,
  useCaseVersion
}: {
  useCaseKey: string;
  useCaseVersion: string;
}) => {
  const jss = useStyles();
  const [isChangelogOpened, setIsChangelogOpened] = useState(false);

  const { loading, data } = useQuery<SchemaDiff, SchemaDiffVariables>(
    QuerySchemaDiff,
    {
      variables: { useCaseKey, useCaseVersion },
      skip: !isChangelogOpened
    }
  );

  const toggleChangelog = () => setIsChangelogOpened(!isChangelogOpened);

  return (
    <>
      <Tooltip title={<Typography>Current version changelog</Typography>}>
        <IconButton size="small" onClick={toggleChangelog}>
          <UpdateIcon />
        </IconButton>
      </Tooltip>
      <Modal open={isChangelogOpened} onClose={toggleChangelog}>
        <Paper className={jss.paper}>
          {loading && <LinearProgress />}
          {data?.cloudPlatform.useCase &&
            data.cloudPlatform.useCase.detail.schemaDiff && (
              <Grid container direction="column">
                <Grid item container justifyContent="space-between">
                  <Typography> Changelog </Typography>
                  <IconButton
                    size="small"
                    onClick={toggleChangelog}
                    data-testid="changelog-close"
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
                <Grid item container className={jss.content} xs>
                  <TechnicalChangelog
                    schemaDiff={data.cloudPlatform.useCase.detail.schemaDiff}
                    schema={data.cloudPlatform.useCase.schema}
                  />
                </Grid>
              </Grid>
            )}
        </Paper>
      </Modal>
    </>
  );
};

export default TechnicalChangelogButton;

import { makeStyles, Theme } from "@material-ui/core";
import useQueryUseCaseName from "./topics/hooks/useQueryUseCaseName";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "&:first-letter": {
      textTransform: "uppercase"
    }
  }
}));
interface UseCaseNameProps {
  useCaseKey: string;
  useCaseVersion: string;
}

export default function UseCaseNameAndVersion({
  useCaseKey,
  useCaseVersion
}: UseCaseNameProps) {
  const { root } = useStyles({});
  const { data } = useQueryUseCaseName({
    useCaseKey,
    useCaseVersion
  });

  const name = data?.cloudPlatform.useCases?.[0]?.detail?.name;
  return (
    <div data-testid="use-case-topic" className={root}>{`${name ||
      useCaseKey} ${useCaseVersion}`}</div>
  );
}

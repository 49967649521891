import { Grid, makeStyles, Typography } from "@material-ui/core";
import { ErrorStack, simplifyErrorStack } from "./errorUtils";

const useStyles = makeStyles(theme => ({
  errorStack: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    color: theme.palette.text.secondary
  }
}));

export default function TraceableErrorDetail({
  errorStacks
}: {
  errorStacks: ErrorStack[];
}) {
  const classNames = useStyles();

  return (
    <Grid item container lg spacing={1}>
      {errorStacks.map((errorStack, index) => (
        <Grid item container key={index} spacing={1} direction="column">
          <div className={classNames.errorStack}>
            {simplifyErrorStack(errorStack).map(({ content }, index) => (
              <Grid item key={index} lg>
                <Typography>{content}</Typography>
              </Grid>
            ))}
          </div>
        </Grid>
      ))}
    </Grid>
  );
}

import { LinearProgress } from "@material-ui/core";
import { InvalidRoute } from "features/shell";
import React from "react";
import useQueryUseCaseByKey from "./hooks/useQueryUseCaseByKey";
import PublishFormWrapper from "./PublishFormWrapper";
export { default as CreateUseCase } from "./PublishFormWrapper";

type UseCasePublisherProps = {
  useCaseKey: string;
};

export const EditUseCase: React.FC<UseCasePublisherProps> = ({
  useCaseKey
}) => {
  const {
    data: useCaseByKey,
    loading: isLoadingUseCase
  } = useQueryUseCaseByKey({
    useCaseKey
  });

  const useCaseDetail = useCaseByKey?.cloudPlatform?.useCase?.detail;
  const isNotTechnical = (tag: string) => !tag.includes(":=");
  const tags = useCaseDetail?.tags?.filter(isNotTechnical) || [];
  const description = useCaseDetail?.description || "";

  if (isLoadingUseCase) return <LinearProgress />;
  if (useCaseDetail)
    return (
      <PublishFormWrapper
        initialState={{
          ...useCaseDetail,
          tags,
          description,
          autoGeneratedKey: false
        }}
        updateUseCase={true}
      />
    );
  return <InvalidRoute />;
};

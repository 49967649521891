import { Button, Link, TextField } from "@material-ui/core";
import "../authenticator-components.css";

export default function RequestCodeForm({
  handleInputChange,
  backToSignIn,
  sendCode
}) {
  return (
    <form className="form" onSubmit={sendCode}>
      <div className="formField">
        <TextField
          name="username"
          label="Email Address *"
          className="input"
          variant="outlined"
          onChange={handleInputChange}
          inputProps={{ "data-testid": "username-input" }}
          type="email"
        />
      </div>
      <div className="confirmSignUpFooter">
        <Link
          data-test="sign-in-create-account-link"
          className="link"
          color="primary"
          onClick={backToSignIn}
        >
          Back to Sign In
        </Link>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="button"
          data-testid="send-code"
        >
          SEND CODE
        </Button>
      </div>
    </form>
  );
}

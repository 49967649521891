import { withAuthenticator } from "aws-amplify-react";
import ConfirmSignUp from "./authenticator-components/CustomConfirmSignUp";
import ForgotPassword from "./authenticator-components/CustomForgotPassword";
import CustomGreetings from "./authenticator-components/CustomGreetings";
import SignIn from "./authenticator-components/CustomSignIn";
import SignUp from "./authenticator-components/CustomSignUp";
import Shell from "./Shell";

const placeholder = "";

const authenticatorComponents = [
  <CustomGreetings />,
  <SignIn override={"SignIn"} />,
  <SignUp
    signUpConfig={{
      hideAllDefaults: true,
      signUpFields: [
        {
          label: "First Name",
          key: "name",
          required: true,
          type: "string",
          displayOrder: 1,
          placeholder
        },
        {
          label: "Last Name",
          key: "family_name",
          required: true,
          type: "string",
          displayOrder: 2,
          placeholder
        },
        {
          label: "Email Address",
          key: "username",
          required: true,
          displayOrder: 3,
          type: "email",
          custom: false,
          placeholder
        },
        {
          label: "Confirm Email Address",
          key: "email",
          required: true,
          displayOrder: 4,
          type: "email",
          placeholder
        },
        {
          label: "Password",
          key: "password",
          required: true,
          displayOrder: 5,
          type: "password",
          placeholder
        }
      ]
    }}
  />,
  <ConfirmSignUp />,
  <ForgotPassword />
];

const ShellWithAuthenticator = withAuthenticator(
  Shell,
  true,
  authenticatorComponents,
  null,
  {
    button: { backgroundColor: "#3AA9AE" },
    navButton: { backgroundColor: "#3AA9AE" },
    toast: {
      display: "flex",
      justifyContent: "space-between",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      zIndex: 99,
      boxShadow: "0 0 5px 0 rgb(0 0 0 / 30%)",
      padding: 16,
      backgroundColor: "#31465f",
      fontSize: 14,
      color: "#fff",
      boxSizing: "border-box"
    }
  }
);

export default ShellWithAuthenticator;

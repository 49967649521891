import { green, grey, red, yellow } from "@material-ui/core/colors";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { AgGridEvent, ColDef } from "ag-grid-community";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AgGridReact } from "ag-grid-react";

import {
  SchemaDiff_cloudPlatform_useCase_detail_schemaDiff,
  SchemaDiff_cloudPlatform_useCase_detail_schemaDiff_input,
  SchemaDiff_cloudPlatform_useCase_detail_schemaDiff_output,
  SchemaDiff_cloudPlatform_useCase_schema,
  SchemaDiff_cloudPlatform_useCase_schema_input,
  SchemaDiff_cloudPlatform_useCase_schema_output
} from "./schema/SchemaDiff";

import clsx from "clsx";
import "./TechnicalChangelog.css";

interface SchemaDiffRow {
  tableCategory: "Input" | "Output";
  tableName: string;
  columnName: string;
  columnType: string | null;
  columnNullable: boolean | null;
  changeCategory:
    | "Added"
    | "Removed"
    | "Changed from"
    | "Changed to"
    | "Unchanged";
}

function notNull<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

const enrichSchemaWithDiff = (
  schema:
    | SchemaDiff_cloudPlatform_useCase_schema_input[]
    | SchemaDiff_cloudPlatform_useCase_schema_output[],
  schemaDiff:
    | SchemaDiff_cloudPlatform_useCase_detail_schemaDiff_input
    | SchemaDiff_cloudPlatform_useCase_detail_schemaDiff_output,
  tableCategory: "Input" | "Output"
) =>
  schema
    .filter(table => table.columns != null)
    .reduce((acc: SchemaDiffRow[], table) => {
      const changedTable = schemaDiff.changedTables.find(
        cT => cT.name === table.name
      );
      const tableIsNew =
        schemaDiff.newTables.find(nT => nT.name === table.name) != null;
      return acc.concat(
        table
          .columns!.filter(notNull)
          .flatMap<SchemaDiffRow>(column => {
            const changedColumn = changedTable?.changedColumns.find(
              cC => cC.to.name === column.name
            );
            if (changedColumn != null) {
              return [
                {
                  tableCategory,
                  tableName: table.name,
                  columnName: column.name,
                  columnType: changedColumn.from.typeName,
                  columnNullable: changedColumn.from.nullable,
                  changeCategory: "Changed from"
                },
                {
                  tableCategory,
                  tableName: table.name,
                  columnName: column.name,
                  columnType: changedColumn.to.typeName,
                  columnNullable: changedColumn.to.nullable,
                  changeCategory: "Changed to"
                }
              ];
            }
            const newColumn = changedTable?.newColumns.find(
              nC => nC.name === column.name
            );
            return {
              tableCategory,
              tableName: table.name,
              columnName: column.name,
              columnType: column.typeName,
              columnNullable: column.nullable,
              changeCategory:
                newColumn != null || tableIsNew ? "Added" : "Unchanged"
            };
          })
          .filter(notNull)
      );
    }, [])
    .concat(
      schemaDiff.changedTables.reduce(
        (acc: SchemaDiffRow[], changedTable) =>
          acc.concat(
            changedTable.missingColumns.map(missingColumn => ({
              tableCategory,
              tableName: changedTable.name,
              columnName: missingColumn.name,
              columnType: missingColumn.typeName,
              columnNullable: missingColumn.nullable,
              changeCategory: "Removed"
            }))
          ),
        []
      )
    )
    .concat(
      schemaDiff.missingTables.reduce(
        (acc: SchemaDiffRow[], missingTable) =>
          missingTable.columns != null
            ? acc.concat(
                missingTable.columns.filter(notNull).map(missingColumn => ({
                  tableCategory,
                  tableName: missingTable.name,
                  columnName: missingColumn.name,
                  columnType: missingColumn.typeName,
                  columnNullable: missingColumn.nullable,
                  changeCategory: "Removed"
                }))
              )
            : acc,
        []
      )
    )
    .sort((a, b) =>
      a.tableName > b.tableName
        ? 1
        : a.tableName < b.tableName
        ? -1
        : a.columnName > b.columnName
        ? 1
        : a.columnName < b.columnName
        ? -1
        : 0
    );

const useStyles = makeStyles((theme: Theme) => ({
  diffChanged: {
    backgroundColor: `${yellow[50]} !important`
  },
  diffAdded: {
    backgroundColor: `${green[50]} !important`
  },
  diffRemoved: {
    backgroundColor: `${red[50]} !important`
  },
  diffUnchanged: {
    backgroundColor: `${grey[50]} !important`
  },
  flex: { flex: 1 }
}));

const TechnicalChangelog = ({
  schemaDiff,
  schema
}: {
  schemaDiff: SchemaDiff_cloudPlatform_useCase_detail_schemaDiff;
  schema: SchemaDiff_cloudPlatform_useCase_schema;
}) => {
  const { diffAdded, diffRemoved, diffChanged, diffUnchanged, flex } =
    useStyles({});
  const sizeColumnsToFit = (params: AgGridEvent) => {
    params.api.sizeColumnsToFit();
  };
  const onFirstDataRendered = (params: AgGridEvent) => {
    sizeColumnsToFit(params);
    params.api.forEachNode(node => {
      if (node.group && node.aggData.changeCategory !== "Unchanged") {
        node.setExpanded(true);
      }
    });
  };

  const columnDefs: ColDef[] = [
    {
      headerName: "table category",
      field: "tableCategory",
      rowGroup: true,
      hide: true
    },
    {
      headerName: "table name",
      field: "tableName",
      rowGroup: true,
      hide: true
    },
    {
      headerName: "column name",
      field: "columnName",
      filter: "agTextColumnFilter"
    },
    {
      headerName: "type",
      field: "columnType",
      filter: "agSetColumnFilter"
    },
    {
      headerName: "nullable",
      field: "columnNullable"
    },
    {
      headerName: "change category",
      field: "changeCategory",
      filter: "agSetColumnFilter",
      aggFunc: values =>
        values.every(value => value === "Added")
          ? "Added"
          : values.every(value => value === "Removed")
          ? "Removed"
          : values.every(value => value === "Unchanged")
          ? "Unchanged"
          : "Changed"
    }
  ];
  const data = enrichSchemaWithDiff(
    schema.input,
    schemaDiff.input,
    "Input"
  ).concat(enrichSchemaWithDiff(schema.output, schemaDiff.output, "Output"));

  return (
    <div id="technicalChangelogGrid" className={clsx("ag-theme-balham", flex)}>
      <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={{
          cellClass: clsx("no-border", "cell")
        }}
        onGridReady={sizeColumnsToFit}
        //@ts-ignore -> AG-2080 no ts definition in v19.0.0
        onFirstDataRendered={onFirstDataRendered}
        onRowGroupOpened={sizeColumnsToFit}
        onGridSizeChanged={sizeColumnsToFit}
        rowData={data}
        floatingFilter={true}
        enableColResize
        enableSorting
        groupMultiAutoColumn={true}
        //@ts-ignore -> AG-2080 no ts definition in v19.0.0
        getRowClass={params => {
          const changeCategory = params.data
            ? params.data.changeCategory
            : params.node.aggData.changeCategory;
          switch (changeCategory) {
            case "Changed from":
            case "Changed to":
            case "Changed":
              return diffChanged;
            case "Added":
              return diffAdded;
            case "Removed":
              return diffRemoved;
            case "Unchanged":
              return diffUnchanged;
          }
        }}
        suppressAggFuncInHeader={true}
      />
    </div>
  );
};

export default TechnicalChangelog;

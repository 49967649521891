import { Button, Grid, Link, TextField, Typography } from "@material-ui/core";
import { Auth } from "aws-amplify";
import { SignIn } from "aws-amplify-react";
import { isEmpty } from "lodash";
import { getConfigValue } from "utils/config";
import "./authenticator-components.css";
import logo from "./internal/logo_petrol.svg";

export default class CustomSignIn extends SignIn {
  signIn(event) {
    const { username, password } = this.inputs;

    event.preventDefault();
    Auth.signIn({ username, password })
      .then(user => {
        this.changeState("signedIn", user);
      })
      .catch(error => {
        this.error(error);
        if (error.code === "UserNotConfirmedException") {
          this.changeState("confirmSignUp", username);
        }
      });
  }

  oauth(event) {
    event.preventDefault();
    Auth.federatedSignIn({
      customProvider: getConfigValue("FEDERATED_CUSTOM_PROVIDER")
    });
  }

  showComponent() {
    const federated = getConfigValue("FEDERATED_CUSTOM_PROVIDER");
    const hasFederatedLogin = !isEmpty(federated);
    const environment = (getConfigValue("ENVIRONMENT") || "").replace("_", " ");
    return (
      <div className="container">
        <Grid container className="grid">
          <Grid item xs={12} md={5} lg={4} className="left">
            <div className="leftContent">
              <img src={logo} alt="logo" className="logo" />
              <form className="form" noValidate>
                <div className="formField">
                  <TextField
                    name="username"
                    label="Email Address *"
                    data-test="username-input"
                    className="input"
                    variant="outlined"
                    onChange={this.handleInputChange}
                  />
                </div>

                <div className="formField">
                  <TextField
                    type="password"
                    name="password"
                    autoComplete="on"
                    label="Password *"
                    data-test="sign-in-password-input"
                    className="input"
                    variant="outlined"
                    onChange={this.handleInputChange}
                  />
                  <div className="forgetPassword">
                    Forgot your password?{" "}
                    <Link
                      data-test="sign-in-forgot-password-link"
                      className="link"
                      color="primary"
                      onClick={() => this.changeState("forgotPassword")}
                    >
                      Reset password
                    </Link>
                  </div>
                </div>

                <div className="footer">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className="button"
                    data-test="sign-in-sign-in-button"
                    onClick={this.signIn}
                  >
                    SIGN IN
                  </Button>
                  {hasFederatedLogin && (
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className="button"
                      onClick={this.oauth}
                    >
                      Use MyID
                    </Button>
                  )}
                  <span className="footerSecondaryContent">
                    No account?{" "}
                    <Link
                      data-test="sign-in-create-account-link"
                      className="link"
                      color="primary"
                      onClick={() => this.changeState("signUp")}
                    >
                      Create account
                    </Link>
                  </span>
                </div>
              </form>
            </div>
          </Grid>
          <Grid item xs={12} md={7} lg={8} className="right">
            <div className="rightContent">
              <Typography className="appTitle" variant="h2">
                Cloud Platform
              </Typography>
              <Typography className="environment">{environment}</Typography>
              <Typography className="appDescription">
                Create, manage and monitor your Use Cases
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

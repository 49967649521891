import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Groups } from "./schema/Groups";
import { Users } from "./schema/Users";

export const usersList = gql`
  query Users {
    accessManagement {
      users {
        firstName
        lastName
        groups {
          name
        }
        isAdmin
        name
        permissions
      }
    }
  }
`;

export const groupsList = gql`
  query Groups {
    accessManagement {
      groups {
        name
      }
    }
  }
`;

export const useQueryGetUsersList = () => useLazyQuery<Users>(usersList);

export const useQueryGetGroupsList = () => useQuery<Groups>(groupsList);

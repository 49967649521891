import { makeStyles } from "@material-ui/core";
import { useWorkspaceNameById } from "./workspaceHooks";

const useStyles = makeStyles(() => ({
  root: {
    "&:first-letter": {
      textTransform: "uppercase"
    }
  }
}));
interface WorkspaceNameProps {
  workspaceId: string;
}

export default function WorkspaceNavigationName({
  workspaceId
}: WorkspaceNameProps) {
  const { root } = useStyles({});
  const { data } = useWorkspaceNameById({
    workspaceId
  });

  const name = data?.projects.workspace?.name;
  return (
    <div data-testid="workspace-nav" className={root}>
      {name || workspaceId}
    </div>
  );
}

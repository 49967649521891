import { Button, Grid, Link, TextField, Typography } from "@material-ui/core";
import { Auth } from "aws-amplify";
import { SignUp } from "aws-amplify-react";
import { getConfigValue } from "utils/config";
import "./authenticator-components.css";
import logo from "./internal/logo_petrol.svg";

export default class CustomSignUp extends SignUp {
  signUp = async event => {
    try {
      const { name, familyName, username, email, password } = this.inputs;
      event.preventDefault();
      const user = await Auth.signUp({
        username,
        password,
        attributes: { name, family_name: familyName, email }
      });
      this.changeState("confirmSignUp", user);
    } catch (error) {
      this.error(error);
      if (error.code === "UsernameExistsException") {
        this.changeState("signIn");
      }
    }
  };

  showComponent() {
    const environment = (getConfigValue("ENVIRONMENT") || "").replace("_", " ");
    return (
      <div className="container">
        <Grid container className="grid">
          <Grid item xs={12} md={5} lg={4} className="left">
            <div className="leftContent">
              <img src={logo} alt="logo" className="logo" />
              <form className="form" onSubmit={this.signUp}>
                <div style={{ display: "flex" }}>
                  <div className="formField" style={{ marginRight: 8 }}>
                    <TextField
                      name="name"
                      className="input"
                      label="First Name"
                      required
                      variant="outlined"
                      onChange={this.handleInputChange}
                      inputProps={{ "data-testid": "first-name-input" }}
                    />
                  </div>
                  <div className="formField">
                    <TextField
                      name="familyName"
                      label="Last Name"
                      required
                      className="input"
                      variant="outlined"
                      onChange={this.handleInputChange}
                      inputProps={{ "data-testid": "last-name-input" }}
                    />
                  </div>
                </div>
                <div className="formField">
                  <TextField
                    name="username"
                    label="Email Address"
                    required
                    data-test="username-input"
                    className="input"
                    variant="outlined"
                    onChange={this.handleInputChange}
                    inputProps={{ "data-testid": "username-input" }}
                    type="email"
                  />
                </div>
                <div className="formField">
                  <TextField
                    name="email"
                    label="Confirm Email Address"
                    required
                    className="input"
                    variant="outlined"
                    onChange={this.handleInputChange}
                    inputProps={{ "data-testid": "confirm-username-input" }}
                    type="email"
                  />
                </div>
                <div className="formField">
                  <TextField
                    type="password"
                    name="password"
                    label="Password"
                    required
                    autoComplete="on"
                    className="input"
                    variant="outlined"
                    onChange={this.handleInputChange}
                    inputProps={{ "data-testid": "password-input" }}
                  />
                </div>
                <div className="signUpFooter">
                  <div className="singInLink">
                    Have an account?{" "}
                    <Link
                      data-test="sign-in-forgot-password-link"
                      className="link"
                      color="primary"
                      onClick={() => this.changeState("signIn")}
                    >
                      Sign in
                    </Link>
                  </div>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="button"
                    data-testid="sign-up-button"
                  >
                    CREATE ACCOUNT
                  </Button>
                </div>
              </form>
            </div>
          </Grid>
          <Grid item xs={12} md={7} lg={8} className="right">
            <div className="rightContent">
              <Typography className="appTitle" variant="h2">
                Cloud Platform
              </Typography>
              <Typography className="environment">{environment}</Typography>
              <Typography className="appDescription">
                Create, manage and monitor your Use Cases
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { DatabaseBackups } from "./schema/DatabaseBackups";

const databaseBackupsList = gql`
  query DatabaseBackups {
    projects {
      databaseTemplates {
        id
        name
      }
    }
  }
`;

export const useDatabaseBackups = () =>
  useQuery<DatabaseBackups>(databaseBackupsList);

import _ from "lodash";

export const queryFromObject = (obj: object) =>
  _(obj)
    .toPairs()
    .filter(([key, value]) => value !== undefined)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");

/**
 * Extends a relative route with the host.
 *
 * @param path: Relative path with leading slash (like: '/sub-route')
 *
 */
export const createRoute = (path: string) => `${process.env.PUBLIC_URL}${path}`;

import { makeStyles, Paper, Typography } from "@material-ui/core";
import { AccessTime } from "@material-ui/icons";
import Tags from "components/base/Tags";
import CircularProgressWithLabel from "components/progress/CircularProgressWithLabel";
import { WorkspaceCard } from "features/workspace";
import { DeepOmit } from "utils/typescript";
import EditProjectButton from "./EditProjectButton";
import { QueryProjectById_projects_project } from "./hooks/schema/QueryProjectById";
import ProjectDefaultImage from "./projectDefaultImage.svg";
import ProjectError from "./ProjectError";
import ProjectStreamingButtons from "./ProjectStreamingButtons";
import { WorkspaceStatus_projects_workspace } from "./schema/WorkspaceStatus";

const useStyles = makeStyles(theme => ({
  paper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "hidden",
    position: "relative"
  },
  details: {
    display: "flex",
    padding: theme.spacing(4)
  },
  defaultIcon: { height: 100, width: 100 },
  image: { maxHeight: "100%", maxWidth: 100, marginRight: 8 },
  anotherDetails: {
    display: "flex",
    flexDirection: "column",
    minWidth: 0,
    textAlign: "left",
    flex: 1,
    marginLeft: theme.spacing(2)
  },
  name: {
    fontWeight: "bold",
    fontSize: 14,
    letterSpacing: 2,
    textTransform: "uppercase"
  },
  description: {
    textAlign: "left",
    color: "#B1B1B1",
    width: "100%",
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
    maxHeight: 300,
    overflow: "auto"
  },
  secondaryText: {
    marginLeft: theme.spacing(1),
    fontSize: 14
  },
  processing: {
    display: "flex",
    alignItems: "center"
  },
  footer: {
    display: "flex",
    alignItems: "center",
    borderTop: "solid 1px rgb(0,0,0,0.08)",
    padding: theme.spacing(2, 0, 2, 4)
  },
  waitingText: {
    color: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center"
  },
  waitingIcon: { height: 40, width: 40 },
  button: { color: theme.palette.common.white },
  padding: { padding: theme.spacing(2, 0, 2, 4) },
  withBorder: {
    padding: theme.spacing(4),
    borderTop: "solid 1px rgb(0,0,0,0.08)"
  }
}));

type ProjectProp = DeepOmit<QueryProjectById_projects_project, "__typename">;
type WorkspaceStatus = DeepOmit<
  WorkspaceStatus_projects_workspace,
  "__typename"
>;

export default function Project({
  project,
  workspaceStatus
}: {
  project: ProjectProp;
  workspaceStatus?: WorkspaceStatus | null;
}) {
  const classNames = useStyles();
  const {
    name,
    id,
    image,
    tags,
    description,
    error,
    state,
    databaseProgress,
    databaseName
  } = project;

  return (
    <Paper className={classNames.paper}>
      <div className={classNames.details}>
        {image ? (
          <div>
            <img src={image} className={classNames.image} alt={id} />
          </div>
        ) : (
          <div>
            <img
              src={ProjectDefaultImage}
              className={classNames.defaultIcon}
              alt="default"
            />
          </div>
        )}
        <div className={classNames.anotherDetails}>
          <Typography className={classNames.name}>{name}</Typography>
          {description ? (
            <Typography className={classNames.description}>
              {description}
            </Typography>
          ) : null}
        </div>
      </div>
      {tags && <Tags tags={tags} />}
      <div className={classNames.padding}>
        <EditProjectButton projectId={id} />
      </div>
      {workspaceStatus && (
        <div className={classNames.withBorder}>
          <WorkspaceCard
            available={
              workspaceStatus.capacity?.computeCapacityStatus.available
            }
            name={workspaceStatus.name}
            loggedInUsers={
              workspaceStatus.capacity?.computeCapacityStatus.inUse
            }
            workspaceId={workspaceStatus.id}
          />
        </div>
      )}
      {error ? (
        <div className={classNames.withBorder}>
          <ProjectError errors={error} database={databaseName} />
        </div>
      ) : null}
      {state !== "Failed" && (
        <div className={classNames.footer}>
          {state === "Created" ? (
            <div>
              <ProjectStreamingButtons
                projectId={id}
                isAvailable={Boolean(workspaceStatus?.isAvailable)}
              />
            </div>
          ) : state === "Scheduled" ? (
            <div className={classNames.waitingText}>
              <AccessTime className={classNames.waitingIcon} />
              <Typography className={classNames.secondaryText}>
                Waiting, another database is already in progress, only one
                database can be created at a time
              </Typography>
            </div>
          ) : state === "InProgress" ? (
            <div className={classNames.processing}>
              <CircularProgressWithLabel value={databaseProgress} />
              <div>
                <Typography className={classNames.secondaryText}>
                  {`In progress, the ${databaseName} database will be created, it can take a while`}
                </Typography>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </Paper>
  );
}

import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { ScsVersions } from "./schema/ScsVersions";

const scsVersionsList = gql`
  query ScsVersions {
    projects {
      workspaces {
        id
        name
      }
    }
  }
`;

export const useScsVersions = () => useQuery<ScsVersions>(scsVersionsList);

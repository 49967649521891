import { IconButton, ListItemText, Menu, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/MoreVert";
import clsx from "clsx";
import Link from "components/base/Link";
import React from "react";
import { useStyles } from "./headerStyle";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5"
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center"
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);

const Header = ({
  name,
  autoSize,
  sizeToFit,
  resizeButtons,
  tableDeepLinks,
  chartDeepLinks = false,
  dataType,
  exploreData = () => {},
  toCalculationResultTable,
  toResultChart,
  backgroundColor
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { header, left, right, icon } = useStyles({});
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const exploreDataLabel = `Explore ${dataType} Data`;
  return (
    <div className={clsx(header, backgroundColor)}>
      <div className={left} data-testid="table-name">
        {name}
      </div>
      <div className={right}>
        <div style={{ display: "flex" }}>
          <IconButton
            aria-label="menu"
            onClick={handleClick}
            data-testid="table-option"
          >
            <MenuIcon className={icon} fontSize="small" />
          </IconButton>
          <StyledMenu
            id="menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {resizeButtons ? (
              <StyledMenuItem
                data-testid="Autosize"
                onClick={() => {
                  autoSize();
                  handleClose();
                }}
              >
                <ListItemText primary="Autosize All Columns" />
              </StyledMenuItem>
            ) : null}
            {resizeButtons ? (
              <StyledMenuItem
                data-testid="size-columns"
                onClick={() => {
                  sizeToFit();
                  handleClose();
                }}
              >
                <ListItemText primary="Size Columns to fit" />
              </StyledMenuItem>
            ) : null}

            {tableDeepLinks && toCalculationResultTable ? (
              <StyledMenuItem
                button
                component={Link}
                to={toCalculationResultTable}
                data-testid="show-table"
              >
                <ListItemText primary="Show only this table" />
              </StyledMenuItem>
            ) : null}

            {chartDeepLinks && toResultChart ? (
              <StyledMenuItem
                button
                component={Link}
                to={toResultChart}
                data-testid="show-charts"
              >
                <ListItemText primary="Show only charts" />
              </StyledMenuItem>
            ) : null}

            {tableDeepLinks || chartDeepLinks ? (
              <StyledMenuItem
                data-testid="explore-data"
                onClick={() => {
                  exploreData();
                  handleClose();
                }}
              >
                <ListItemText primary={exploreDataLabel} />
              </StyledMenuItem>
            ) : null}
          </StyledMenu>
        </div>
      </div>
    </div>
  );
};

export default Header;

import { RouterModel } from "mst-react-router";

const ROUTE_USER_ROLE = "userRole";
const ROUTE_USE_CASE_KEY = "useCaseKey";
const ROUTE_USE_CASE_VERSION = "useCaseVersion";
const ROUTE_USE_CASE_TOPIC = "useCaseTopic";
const ROUTE_USE_CASE_SUBTOPIC = "useCaseSubtopic";
const ROUTE_USE_CASE_DATA_TYPE = "useCaseDataType";
const ROUTE_USE_CASE_DATA_ADDRESS = "useCaseDataAddress";

export default RouterModel.views(self => ({
  get pathArgs() {
    const { location: { pathname = "" } = {} } = self;
    return pathname
      .replace(process.env.PUBLIC_URL, "")
      .trim()
      .split("/")
      .filter(value => value !== "");
  },
  get routeElements() {
    return [
      [ROUTE_USER_ROLE, this.pathArgs[0]],
      [ROUTE_USE_CASE_KEY, this.pathArgs[1]],
      [ROUTE_USE_CASE_VERSION, this.pathArgs[2]],
      [ROUTE_USE_CASE_TOPIC, this.pathArgs[3]],
      [ROUTE_USE_CASE_SUBTOPIC, this.pathArgs[4]],
      [ROUTE_USE_CASE_DATA_TYPE, this.pathArgs[5]],
      [ROUTE_USE_CASE_DATA_ADDRESS, this.pathArgs[6]]
    ];
  },
  routeElementValue(name) {
    const element = this.routeElements.find(element => element[0] === name);
    if (element) {
      return element[1];
    }
  }
}))
  .actions(self => ({
    /**
     * @param {string[]} elements
     */
    pushPathElements(elements) {
      self.push({
        ...self.location,
        pathname: `${process.env.PUBLIC_URL}/${elements
          .filter(v => v)
          .join("/")}`
      });
    },
    /**
     * @param {string} param
     * @param {string} value
     */
    updateRouteParam(param, value) {
      const routeString = elements =>
        `/${elements.map(r => `{${r[0]}}`).join("/")}`;

      const index = self.routeElements.findIndex(
        element => element[0] === param
      );

      if (index === -1)
        throw new Error(
          `Invalid route. Could not navigate to ${param}. Unrecognized route param. Route is structured as ${routeString(
            self.routeElements
          )}`
        );

      const previousRouteElements = self.routeElements.slice(0, index);
      const missingRouteElements = previousRouteElements.filter(
        element => !element[1]
      );
      if (missingRouteElements.length > 0)
        throw new Error(
          `Invalid route. Could not navigate to '${param}=${value}'. Missing route parts: ${routeString(
            missingRouteElements
          )}`
        );

      this.pushPathElements([
        ...previousRouteElements.map(element => element[1]),
        value
      ]);
    },
    /**
     * @param {string} param
     * @param {string} value
     */
    pathname(param, value) {
      const routeString = elements =>
        `/${elements.map(r => `{${r[0]}}`).join("/")}`;
      const index = self.routeElements.findIndex(
        element => element[0] === param
      );
      if (index === -1)
        throw new Error(
          `Invalid route. Could not navigate to ${param}. Unrecognized route param. Route is structured as ${routeString(
            self.routeElements
          )}`
        );

      const previousRouteElements = self.routeElements.slice(0, index);
      const missingRouteElements = previousRouteElements.filter(
        element => !element[1]
      );
      if (missingRouteElements.length > 0)
        throw new Error(
          `Invalid route. Could not navigate to '${param}=${value}'. Missing route parts: ${routeString(
            missingRouteElements
          )}`
        );

      const pathElements = [
        ...previousRouteElements.map(element => element[1]),
        value
      ];

      return `${process.env.PUBLIC_URL}/${pathElements.join("/")}`;
    },
    openInNewTab: url => {
      window.open(url);
    }
  }))
  .views(self => ({
    pathToUserRole(value) {
      return self.pathname(ROUTE_USER_ROLE, value);
    },
    pathToUseCase(value) {
      return self.pathname(ROUTE_USE_CASE_KEY, value);
    },
    pathToUseCaseVersion(value) {
      return self.pathname(ROUTE_USE_CASE_VERSION, value);
    },
    pathToUseCaseTopic(value) {
      return self.pathname(ROUTE_USE_CASE_TOPIC, value);
    }
  }))
  .actions(self => ({
    /**
     * @param {string} role
     */
    setUserRole(role) {
      self.updateRouteParam(ROUTE_USER_ROLE, role);
    },
    /**
     * @param {string} key
     */
    setUseCaseKey(key) {
      self.updateRouteParam(ROUTE_USE_CASE_KEY, key);
    },
    /**
     * @param {string} version
     */
    setUseCaseVersion(version) {
      self.updateRouteParam(ROUTE_USE_CASE_VERSION, version);
    },
    /**
     * @param {string} topic
     */
    setUseCaseTopic(topic) {
      self.updateRouteParam(ROUTE_USE_CASE_TOPIC, topic);
    },
    /**
     * @param {string} subtopic
     */
    setUseCaseSubtopic(subtopic) {
      self.updateRouteParam(ROUTE_USE_CASE_SUBTOPIC, subtopic);
    },
    /**
     * @param {string} type
     */
    setUseCaseDataType(type) {
      self.updateRouteParam(ROUTE_USE_CASE_DATA_TYPE, type);
    },
    /**
     * @param {string} address
     */
    setUseCaseDataAddress(address) {
      self.updateRouteParam(ROUTE_USE_CASE_DATA_ADDRESS, address);
    }
  }))
  .views(self => ({
    get userRole() {
      return self.routeElementValue(ROUTE_USER_ROLE);
    },
    get useCaseKey() {
      return self.routeElementValue(ROUTE_USE_CASE_KEY);
    },
    get useCaseVersion() {
      return self.routeElementValue(ROUTE_USE_CASE_VERSION);
    },
    get useCaseTopic() {
      return self.routeElementValue(ROUTE_USE_CASE_TOPIC);
    },
    get useCaseSubtopic() {
      return self.routeElementValue(ROUTE_USE_CASE_SUBTOPIC);
    },
    get useCaseDataType() {
      return self.routeElementValue(ROUTE_USE_CASE_DATA_TYPE);
    },
    get useCaseDataAddress() {
      return self.routeElementValue(ROUTE_USE_CASE_DATA_ADDRESS);
    },
    get currentPathName() {
      return `${process.env.PUBLIC_URL}/${self.pathArgs.join("/")}`;
    }
  }));

import {
  ClickAwayListener,
  Fade,
  LinearProgress,
  makeStyles,
  Paper,
  Popper
} from "@material-ui/core";
import React, { Suspense, useEffect, useState } from "react";
import { createRoute } from "utils/url";
const MarkdownPreview = React.lazy(() => import("./MarkdownPreview"));

const useStyles = makeStyles(theme => ({
  root: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center"
    }
  },
  paper: {
    zIndex: 1000,
    minHeight: "40vh",
    maxHeight: "85vh",
    overflow: "auto",
    padding: "35px",
    backgroundColor: "#ebf0f5"
  },
  popper: {
    top: 68,
    zIndex: 1000,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      top: 66
    }
  }
}));

export default function EnvironmentDetails({
  environmentCaption,
  title,
  open,
  closePopper,
  anchorEl,
  children
}: {
  environmentCaption?: string;
  title: string;
  open: boolean;
  closePopper: () => void;
  anchorEl: HTMLElement | null;
  children(title: string, ec?: string): JSX.Element;
}) {
  const { paper, popper } = useStyles({});
  const [changelogMarkdown, setChangelogMarkdown] = useState<null | string>(
    null
  );

  const handleClose = () => {
    closePopper();
    setChangelogMarkdown(null);
  };

  useEffect(() => {
    if (open && !changelogMarkdown) {
      fetch(createRoute("/CHANGELOG.md"))
        .then(r => (r.ok ? r.text() : "# Unable to load changelog"))
        .then(changelogMarkdown => setChangelogMarkdown(changelogMarkdown));
    }
  }, [open, changelogMarkdown]);

  return (
    <>
      {children(title, environmentCaption)}
      <Popper
        open={open}
        anchorEl={anchorEl}
        transition
        popperOptions={{
          modifiers: {
            computeStyle: { enabled: false, gpuAcceleration: false }
          }
        }}
        className={popper}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Fade {...TransitionProps}>
              <Paper className={paper}>
                <Suspense fallback={<LinearProgress />}>
                  {!changelogMarkdown ? (
                    <LinearProgress />
                  ) : (
                    <MarkdownPreview
                      className="markdown"
                      markedOptions={{
                        gfm: false,
                        sanitize: false,
                        tables: true
                      }}
                      value={changelogMarkdown}
                    />
                  )}
                </Suspense>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
}

export const dateComparator = (
  filterLocalDateAtMidnight: Date | null | string,
  cellValue: Date | null | string
) => {
  if (!filterLocalDateAtMidnight && !cellValue) {
    return 0;
  }
  if (!filterLocalDateAtMidnight) {
    return 1;
  }
  if (!cellValue) {
    return -1;
  }
  const dateWithoutTime1 =
    typeof filterLocalDateAtMidnight === "string"
      ? new Date(new Date(filterLocalDateAtMidnight).toDateString())
      : filterLocalDateAtMidnight;
  const dateWithoutTime2 =
    typeof cellValue === "string"
      ? new Date(new Date(cellValue).toDateString())
      : cellValue;

  if (dateWithoutTime1?.getTime() === dateWithoutTime2?.getTime()) {
    return 0;
  }
  if (dateWithoutTime2 < dateWithoutTime1) {
    return -1;
  }
  if (dateWithoutTime2 > dateWithoutTime1) {
    return 1;
  }
};

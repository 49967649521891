import { ErrorBox, ErrorMessage } from "components/error";
import gql from "graphql-tag";
import useResettableMutation from "hooks/useResettableMutation";
import { notNullOrUndefined } from "utils/typescript";
import { ExpireSession, ExpireSessionVariables } from "./schema/ExpireSession";
import Sessions from "./Sessions";
import { sessionsList, useSessionsDataWithPolling } from "./sessionsHooks";

const mutationExpireSession = gql`
  mutation ExpireSession($workspaceId: ID!, $userName: String!) {
    projects {
      workspace(workspaceId: $workspaceId) {
        expireUserSession(userName: $userName)
      }
    }
  }
`;

export default function SessionsWrapper({
  workspaceId,
  canExpireSession
}: {
  workspaceId: string;
  canExpireSession: boolean;
}) {
  const {
    data,
    loading: sessionsDataPolling,
    error: sessionsError
  } = useSessionsDataWithPolling({
    workspaceId
  });

  const [
    expireSession,
    { loading: expireSessionLoading, error: expireSessionError, reset }
  ] = useResettableMutation<ExpireSession, ExpireSessionVariables>(
    mutationExpireSession,
    {
      refetchQueries: [{ query: sessionsList }]
    }
  );

  const workspace = data?.projects.workspace;
  if (data && !workspace) {
    return (
      <ErrorMessage message={`No workspace found for id '${workspaceId}'.`} />
    );
  }
  var errors = [sessionsError, expireSessionError].filter(notNullOrUndefined);

  if (errors.length > 0)
    return (
      <ErrorBox
        apolloError={errors}
        onClose={reset}
        closable={!sessionsError}
      />
    );

  return (
    <Sessions
      sessions={workspace?.streamingSessions}
      canExpireSession={canExpireSession}
      expireSession={userName => {
        if (canExpireSession) {
          expireSession({ variables: { userName, workspaceId } });
        }
      }}
      loading={sessionsDataPolling || expireSessionLoading}
    />
  );
}

import { Dialog, DialogContent, makeStyles } from "@material-ui/core";
import { ApolloError } from "apollo-client";
import ErrorBox from "./ErrorBox";

const useStyles = makeStyles(theme => ({
  dialogContent: {
    overflow: "hidden",
    padding: 0,
    "&:first-child": {
      paddingTop: 0
    }
  },
  customWidth: {
    maxWidth: "none",
    minWidth: 400
  }
}));

export default function ErrorBoxDialog({
  apolloError,
  onClose
}: {
  /** The Dialog will be open, if apolloError is set. */ apolloError?:
    | ApolloError
    | ApolloError[]
    | null;
  /** Reset the error on close. */ onClose: () => void;
}) {
  const classes = useStyles();
  return apolloError ? (
    <Dialog
      open
      onClose={onClose}
      BackdropProps={{
        style: {
          backgroundColor: "rgb(0,0,0,0.2)"
        }
      }}
      PaperProps={{
        style: {
          boxShadow: "none"
        }
      }}
      classes={{ paperScrollPaper: classes.customWidth }}
      data-testid="error-box-dialog"
    >
      <DialogContent className={classes.dialogContent}>
        <ErrorBox apolloError={apolloError} onClose={onClose} closable />
      </DialogContent>
    </Dialog>
  ) : null;
}

import { useLazyQuery } from "@apollo/react-hooks";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  Tooltip
} from "@material-ui/core";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import ApolloClient from "apollo-client/ApolloClient";
import { ErrorMessage } from "components/error";
import gql from "graphql-tag";
import * as React from "react";
import {
  UserPermissionsQuery,
  UserPermissionsQueryVariables
} from "./schema/UserPermissionsQuery";

const useStyles = makeStyles(() => ({
  listHeader: {
    background: "aliceblue"
  }
}));

export default function UserPermissions({
  userName,
  client
}: {
  userName: string;
  client: ApolloClient<any>;
}) {
  const classes = useStyles();
  const [loadPermissions, { data: userPermissions, loading, error }] =
    useLazyQuery<UserPermissionsQuery, UserPermissionsQueryVariables>(
      gql`
        query UserPermissionsQuery($userName: String!) {
          accessManagement {
            user(name: $userName) {
              firstName
              lastName
              permissions
            }
          }
        }
      `,
      { variables: { userName }, client }
    );
  const [isPermissionsDialogOpen, setIsPermissionDialogOpen] =
    React.useState(false);
  const user = userPermissions?.accessManagement.user;
  return (
    <>
      <Tooltip title="View user permissions">
        <IconButton
          data-testid="show-user-permissions"
          onClick={() => {
            setIsPermissionDialogOpen(true);
            loadPermissions();
          }}
        >
          <LockOpenIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={isPermissionsDialogOpen}
        onClose={() => setIsPermissionDialogOpen(false)}
        BackdropProps={{
          style: {
            backgroundColor: "rgb(0,0,0,0.2)"
          }
        }}
        PaperProps={{
          style: {
            boxShadow: "none"
          }
        }}
      >
        <DialogContent>
          {loading && <CircularProgress />}
          {error && <ErrorMessage message={error.message} />}
          {user ? (
            <List
              dense
              subheader={
                <ListSubheader className={classes.listHeader}>
                  {user.firstName} {user.lastName}'s permissions
                </ListSubheader>
              }
            >
              {user.permissions.map((permission: string) => (
                <ListItem key={permission}>
                  <ListItemIcon>
                    <LockOpenIcon />
                  </ListItemIcon>
                  <ListItemText primary={permission} />
                </ListItem>
              ))}
            </List>
          ) : null}
        </DialogContent>
      </Dialog>
    </>
  );
}

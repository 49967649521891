import { LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Charts } from "components/chart";
import { ErrorBox } from "components/error";
import React from "react";
import CalculationNotExist from "./CalculationNotExist";
import { extractCalculationData } from "./calculationResultUtils";
import { useQueryCalculationSchema } from "./hooks/calculationResultHooks";

type CalculationResultChartsProps = {
  useCaseKey: string;
  useCaseVersion: string;
  calculationId: string;
};

const useStyles = makeStyles(theme => ({
  margin: {
    marginBottom: theme.spacing(1)
  }
}));

export const CalculationResultInputCharts: React.FC<
  CalculationResultChartsProps
> = ({ useCaseKey, useCaseVersion, calculationId }) => {
  const { data, error, loading } = useQueryCalculationSchema({
    useCaseKey,
    useCaseVersion,
    calculationId
  });
  const { margin } = useStyles({});

  const calculation = data?.cloudPlatform.useCases?.[0]?.calculation;

  if (error) return <ErrorBox apolloError={error} />;
  if (loading) return <LinearProgress className={margin} />;
  if (!calculation) {
    return <CalculationNotExist />;
  }
  const { inputDataAddress } = extractCalculationData(calculation);
  return (
    <Charts
      calculationId={calculationId}
      dataSource={inputDataAddress}
      useCaseKey={useCaseKey}
      useCaseVersion={useCaseVersion}
      chartSources="use-case-input"
    />
  );
};

export const CalculationResultOutputCharts: React.FC<
  CalculationResultChartsProps
> = ({ useCaseKey, useCaseVersion, calculationId }) => {
  const { data, error, loading } = useQueryCalculationSchema({
    useCaseKey,
    useCaseVersion,
    calculationId
  });
  const { margin } = useStyles({});

  const calculation = data?.cloudPlatform.useCases?.[0]?.calculation;

  if (error) return <ErrorBox apolloError={error} />;
  if (loading) return <LinearProgress className={margin} />;
  if (!calculation) {
    return <CalculationNotExist />;
  }
  const { state, resultDataAddress } = extractCalculationData(calculation);
  if (state !== "Completed") return null;
  return (
    <Charts
      calculationId={calculationId}
      dataSource={resultDataAddress!}
      useCaseKey={useCaseKey}
      useCaseVersion={useCaseVersion}
      chartSources="use-case-result"
    />
  );
};

import { makeStyles } from "@material-ui/core";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import _ from "lodash";
import { observer as HooksObserver } from "mobx-react-lite";
import { useEffect, useImperativeHandle, useReducer, useRef } from "react";

const useStyles = makeStyles({ flex: { flex: "1 1 auto" } });

const TableSetGrid = (
  {
    useCaseKey,
    useCaseVersion = null,
    dataKey,
    calculationId,
    name,
    columnDefs,
    loadData
  },
  ref
) => {
  const { flex } = useStyles({});
  const mountedRef = useRef(false);
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { grid: null }
  );

  const dataSetViewModelToObjectRows = ({ rows }) => {
    const columnNames = columnDefs.map(({ field }) => field);
    const rowObjects = _(rows)
      .map(row => _.zipObjectDeep(columnNames, row))
      .value();
    return rowObjects;
  };
  const GridReference = useRef();
  const defaultColDef = { suppressMenu: false, resizable: true };
  const onGridReady = params => {
    params.api.setServerSideDatasource({
      getRows: async params => {
        const request = params.request;
        const table = dataKey
          ? await loadData(
              useCaseKey,
              useCaseVersion,
              dataKey,
              name,
              request.startRow,
              request.endRow - request.startRow
            )
          : await loadData(
              useCaseKey,
              useCaseVersion,
              calculationId,
              name,
              request.startRow,
              request.endRow - request.startRow
            );

        if (table) {
          const rows = dataSetViewModelToObjectRows(table);
          let lastRow = -1;
          const cacheBlockSize =
            params.parentNode.gridOptionsWrapper.gridOptions.cacheBlockSize;
          if (rows.length < cacheBlockSize) {
            lastRow = request.startRow + rows.length;
          }
          params.successCallback(rows, lastRow);
        }
      }
    });
    safeSetState({ grid: params });
  };
  useEffect(() => {
    mountedRef.current = true;
    return () => (mountedRef.current = false);
  }, []);
  const safeSetState = (...args) => {
    mountedRef.current && setState(...args);
  };
  useImperativeHandle(ref, () => ({
    grid() {
      return state.grid;
    }
  }));
  return (
    <div className={flex}>
      <div
        id="myGrid"
        style={{
          height: "100%",
          width: "100%"
        }}
        className="ag-theme-balham"
      >
        <AgGridReact
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          rowModelType="serverSide"
          cacheBlockSize={100}
          maxBlocksInCache={10}
          defaultColDef={defaultColDef}
          enableColResize={true}
          ref={GridReference}
        />
      </div>
    </div>
  );
};

export default HooksObserver(TableSetGrid, { forwardRef: true });

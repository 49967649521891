import { makeStyles, Typography } from "@material-ui/core";
import ResultsIcon from "mdi-material-ui/TableSearch";
import { useQueryUseCaseJobsCount } from "../results/hooks/AllCalculationsResultsHooks";
import Topic from "../Topic";

const useStyles = makeStyles(theme => ({
  right: {
    flex: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    textTransform: "capitalize",
    padding: theme.spacing(1),
    flexDirection: "column"
  },
  text: {
    textAlign: "left",
    color: "#fff",
    width: "100%",
    textTransform: "capitalize",
    fontWeight: "bold"
  },
  topicColor: {
    backgroundColor: theme.palette.primary.dark,
    marginRight: theme.spacing(4)
  },
  divider: {
    height: 32,
    borderLeft: "solid 2px #ffffff"
  },
  calculationNumber: { fontSize: 60 },
  badge: { fontSize: 12, marginBottom: -10 },
  flex: { display: "flex", alignItems: "center" },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    marginRight: theme.spacing(3)
  },
  allCalculations: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(1)
  },
  icon: { marginRight: 16, fontSize: 60 }
}));

type CalculationsCardProps = {
  useCaseKey: string;
  useCaseVersion?: string;
};

export default function CalculationsCard({
  useCaseKey,
  useCaseVersion
}: CalculationsCardProps) {
  const jss = useStyles();
  const { data: allCalculations, loading: loadingAllCalculations } =
    useQueryUseCaseJobsCount({
      useCaseKey,
      useCaseVersion,
      scheduler: null
    });
  const { data: myCalculations, loading: loadingMyCalculations } =
    useQueryUseCaseJobsCount({
      useCaseKey,
      useCaseVersion,
      scheduler: "me"
    });
  return (
    <Topic
      title="Calculations"
      icon={<ResultsIcon fontSize="large" className={jss.icon} />}
      dataTest="topic-show-calculations"
      isLoading={loadingMyCalculations || loadingAllCalculations}
      to="./calculations"
      colorClassName={jss.topicColor}
      disabledMessage={
        allCalculations?.cloudPlatform.useCase?.calculationsCount !==
        0 ? undefined : (
          <span>Schedule a calculation to see results</span>
        )
      }
    >
      {myCalculations && allCalculations ? (
        <div className={jss.flex}>
          <div className={jss.flexColumn}>
            <Typography className={jss.badge}>Mine</Typography>
            <Typography className={jss.calculationNumber}>
              {myCalculations.cloudPlatform.useCase?.calculationsCount}
            </Typography>
          </div>
          <div className={jss.divider}></div>
          <div className={jss.allCalculations}>
            <Typography className={jss.badge}>All</Typography>
            <Typography className={jss.calculationNumber}>
              {allCalculations.cloudPlatform.useCase?.calculationsCount}
            </Typography>
          </div>
        </div>
      ) : null}
    </Topic>
  );
}

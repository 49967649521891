import { LinearProgress } from "@material-ui/core";
import NoItemFound from "components/base/NoItemFound";
import AddProjectFormWrapper from "./AddProjectFormWrapper";
import useQueryProjectById from "./hooks/useQueryProjectById";

type AddProjectProps = {
  projectId: string;
};

export default function EditProject({ projectId }: AddProjectProps) {
  const { data: projectById, loading: isLoadingProject } = useQueryProjectById({
    projectId
  });

  const project = projectById?.projects.project;
  if (isLoadingProject) return <LinearProgress />;
  if (!project) {
    return (
      <NoItemFound
        title="We could not find this project. Would you like to create one?"
        linkTarget="/projects/create"
        linkCaption="Create a new project"
      />
    );
  }
  return (
    <AddProjectFormWrapper
      project={project}
      editProject={true}
      projectId={projectId}
    />
  );
}

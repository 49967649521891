import { Auth } from "aws-amplify";
import { Greetings, Nav, NavBar, NavRight } from "aws-amplify-react";
import { createBrowserHistory } from "history";
import { ModelProvider } from "model";
import { Fragment } from "react";
import UserGreeting from "./internal/UserGreeting";
import Logo from "./internal/Logo";
import "./internal/markdown.css";
import NavigationActions from "./internal/NavigationActions";
import NavigationBreadCrumbs from "./internal/NavigationBreadCrumbs";

class CustomGreetings extends Greetings {
  signOut = () => {
    Auth.signOut()
      .then(() => {
        this.setState({ authState: "signIn" });
      })
      .catch(e => {
        this.error(e);
      });
  };

  render() {
    const authState = this.props.authState || this.state.authState;
    const { theme, authData } = this.props;
    const greeting =
      authState === "signedIn" ? (
        <UserGreeting
          signOut={this.signOut}
          name={authData?.attributes?.name || ""}
          email={authData?.attributes?.email || ""}
          familyName={authData?.attributes?.family_name || ""}
        />
      ) : authState === "signIn" ? (
        this.noUserGreetings(theme)
      ) : (
        <div>loading...</div>
      );

    return (
      <ModelProvider history={createBrowserHistory()}>
        <Fragment>
          <NavBar
            style={{
              border: "none",
              borderBottom: "1px solid #dfe6ed",
              backgroundColor: "#ffffff",
              display: authState === "signedIn" ? "block" : "none"
            }}
          >
            <Nav>
              <NavRight
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Logo />
                {authState === "signedIn" && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      minWidth: 0,
                      flex: 1
                    }}
                  >
                    <NavigationBreadCrumbs />
                    <NavigationActions greeting={greeting}></NavigationActions>
                  </div>
                )}
              </NavRight>
            </Nav>
          </NavBar>
        </Fragment>
      </ModelProvider>
    );
  }
}

export default CustomGreetings;

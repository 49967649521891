import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { HttpLink } from "apollo-link-http";
import { Auth } from "aws-amplify";
import { getConfigValue } from "utils/config";

const cache = new InMemoryCache();

const errorLink = onError(({ operation, response }) => {
  const fetchResponse = operation.getContext().response;

  // Skip trace ID augmentation in case of mocked requests
  if (!fetchResponse) return;

  const headerName = "x-amzn-trace-id";
  const { headers } = fetchResponse;
  const traceId = headers.get(headerName);
  if (response && response.errors && traceId) {
    response.errors.forEach(error => {
      error.extensions["traceId"] = traceId;
    });
  }
});

const authLink = setContext(
  (request, res) =>
    new Promise((resolve, reject) => {
      Auth.currentSession().then(session => {
        // TODO: 7379 clarify if idToken is a custom field and can't be typed
        const token = (session as any).idToken.jwtToken;
        resolve({
          headers: {
            Authorization: token
          }
        });
      });
    })
);
const httpLink = new HttpLink({ uri: getConfigValue("GRAPHQL_ENDPOINT") });

const client = new ApolloClient({
  cache,

  link: ApolloLink.from([errorLink, authLink.concat(httpLink)]),
  // https://www.apollographql.com/docs/react/api/apollo-client/#example-defaultoptions-object
  // The apollo docs state that the <Query/> component use watchQuery default
  // options. From testing the useQuery Hook does the same, thus watchQuery has
  // to be used in order to change default options for the useQuery Hook.
  defaultOptions: {
    mutate: {
      // https://github.com/apollographql/apollo-client/issues/4577#issuecomment-472791200
      // Apollo docs actually suggest to not set a fetch policy for mutations as
      // the only option which does not throw an exception is "no-cache"
    },
    // https://www.apollographql.com/docs/react/api/apollo-client/#apolloclient-functions
    // The docs indicate that watchQuery might receive updates on cache changes
    // therefore it might be best to keep the cache even tho the defaults are
    // set to never use but just to fill it -> use network-only instead of
    // no-cache for possible optimization without getting old cached data.
    watchQuery: {
      fetchPolicy: "network-only"
    },
    query: {
      fetchPolicy: "network-only"
    }
  }
});

function clientFactory() {
  // reuse the same client for now to ensure caching is used properly, might be clarified if this must be a singleton
  return client;
}

export default clientFactory;

import { createTheme } from "@material-ui/core";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3AA9AE",
      dark: "#318CA9"
    },
    secondary: { main: "#ffb900" },
    error: {
      main: "#f44336"
    }
  }
});

export default theme;

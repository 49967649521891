import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  tabsRoot: {
    height: "100%",
    "&>:nth-child(1)": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      "&>:nth-child(1)": {
        flex: "1 1 auto"
      }
    }
  },
  flexContainer: { display: "flex", flexDirection: "column", height: "100%" },
  tableView: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto"
  },
  icon: {
    marginLeft: 4
  },
  chartsContainer: {
    height: "100%",
    border: "1px solid #BDC3C7",
    zIndex: 1200,
    overflow: "hidden",
    paddingBottom: 1
  }
}));

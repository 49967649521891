import { makeStyles, Theme } from "@material-ui/core";
import { Location, LocationDescriptor } from "history";
import { isFunction, isString, trimEnd } from "lodash";
import React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from "react-router-dom";
import resolvePathname from "resolve-pathname";
import { createRoute } from "utils/url";

export type LinkProps = RouterLinkProps;

const useStyles = makeStyles((theme: Theme) => ({
  link: { textDecoration: "none" }
}));

const isAbsolute = (to: string) => to.charAt(0) === "/";

const getAbsolutePath = (
  location: Location,
  to: LocationDescriptor
): LocationDescriptor => {
  const relativePath = isString(to) ? to : to.pathname || "";
  const pathname = isAbsolute(relativePath)
    ? createRoute(relativePath)
    : resolvePathname(relativePath, `${trimEnd(location.pathname, "/")}/`);
  return isString(to) ? pathname : { ...to, pathname };
};

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  // Function components cannot be given refs.
  // Use forwardRef to get a reference to the rendered DOM (anchor) element
  ({ children, to, ...otherProps }, ref) => {
    const { link } = useStyles();
    const toFunc: LinkProps["to"] = isFunction(to)
      ? location => getAbsolutePath(location, to(location))
      : location => getAbsolutePath(location, to);

    return (
      <RouterLink innerRef={ref} className={link} to={toFunc} {...otherProps}>
        {children}
      </RouterLink>
    );
  }
);

export default Link;

import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography
} from "@material-ui/core";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { tooltipFormatter } from "components/grid/dateFormatter";
import "components/grid/gridToolPanel.css";
import prettyBytes from "pretty-bytes";
import { Component, Fragment } from "react";
import { createRoute } from "utils/url";
import { UseCaseData_cloudPlatform_useCase_data } from "./schema/UseCaseData";

interface AgGridReactWithHistory extends AgGridReact {
  history: any;
  scheduleCalculation: (row: any) => void;
  useCaseKey: string;
  useCaseVersion: string;
}

export default class UploadedDataToolsPanel extends Component<
  AgGridReactWithHistory,
  { row: UseCaseData_cloudPlatform_useCase_data | null }
> {
  constructor(props: AgGridReactWithHistory) {
    super(props);
    this.state = { row: null };

    props.api.addEventListener(
      "selectionChanged",
      this.updateSelectedRow.bind(this)
    );

    // event not fired, ag-version might not yet support it
    props.api.addEventListener(
      "toolPanelVisibleChanged",
      this.updateToolPanelVisibleChanged.bind(this)
    );
  }

  updateSelectedRow() {
    const [row] = this.props.api.getSelectedRows();
    if (row) {
      this.props.api.openToolPanel("uploadedDataTools");
      this.setState({ row });
    } else {
      this.props.api.closeToolPanel();
      this.setState({ row: null });
    }
  }

  updateToolPanelVisibleChanged() {
    if (!this.props.api.isToolPanelShowing()) {
      this.props.api.clearRangeSelection();
    }
  }

  render() {
    const [row] = this.props.api.getSelectedRows();
    const lastModified = this.state.row?.detail?.lastModified;
    return (
      <Paper className="root">
        <Fragment>
          <div className="flex">
            <Typography className="title">DETAILS</Typography>
            <Button
              data-testid="data-view-button"
              size="small"
              variant="contained"
              className="data-view-button"
              onClick={() => {
                const id = this.state.row?.detail?.key.split("/").slice(-1)[0];
                this.props.history.push({
                  pathname: createRoute(`./data/${id}`),
                  state: {
                    dataKey: this.state.row?.detail?.key
                  }
                });
              }}
            >
              Data view
            </Button>
          </div>
          <Divider />
          <List component="nav" className="list">
            <ListItem>
              <ListItemText
                primary="Uploaded data name"
                secondary={this.state.row?.detail?.metaData.displayName}
                data-testid="uploaded-data-name"
                classes={{
                  primary: "primary",
                  secondary: "secondary"
                }}
              />
            </ListItem>
            {lastModified && (
              <ListItem>
                <ListItemText
                  secondary={tooltipFormatter(lastModified)}
                  primary="Upload date"
                  autoCapitalize="characters"
                  classes={{
                    primary: "primary",
                    secondary: "secondary"
                  }}
                />
              </ListItem>
            )}
            <ListItem>
              <ListItemText
                primary="User"
                secondary={this.state.row?.detail?.metaData.creator}
                classes={{
                  primary: "primary",
                  secondary: "secondary"
                }}
              />
            </ListItem>

            {this.state.row?.detail?.size && (
              <ListItem>
                <ListItemText
                  secondary={prettyBytes(this.state.row?.detail?.size)}
                  primary="Size"
                  classes={{
                    primary: "primary",
                    secondary: "secondary"
                  }}
                />
              </ListItem>
            )}
          </List>
          <Divider />

          <div className="button-root">
            <Button
              data-testid="schedule-btn"
              size="small"
              variant="contained"
              className="button"
              onClick={() => this.props.scheduleCalculation(row)}
            >
              Schedule calculation
            </Button>
          </div>
        </Fragment>
      </Paper>
    );
  }
}

import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  QueryUseCaseByKey,
  QueryUseCaseByKeyVariables
} from "./schema/QueryUseCaseByKey";

export const queryUseCaseByKey = gql`
  query QueryUseCaseByKey($useCaseKey: String!) {
    cloudPlatform {
      useCase(useCaseKey: $useCaseKey) {
        detail {
          description
          image
          key
          name
          tags
          version
        }
      }
    }
  }
`;

export default function useQueryUseCaseByKey(
  variables: QueryUseCaseByKeyVariables
) {
  return useQuery<QueryUseCaseByKey, QueryUseCaseByKeyVariables>(
    queryUseCaseByKey,
    {
      variables
    }
  );
}

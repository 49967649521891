import { CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/Error";
import { JobState } from "model/schema/globalTypes";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
    height: 150,
    width: 200,
    border: `solid 1px ${theme.palette.primary.main}`,
    borderRadius: 4,
    boxShadow:
      "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
    margin: theme.spacing(1)
  },
  text: {
    color: "#8a99a2",
    marginTop: theme.spacing(2)
  },
  failed: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
    minHeight: 150,
    backgroundColor: theme.palette.error.light,
    border: `solid 1px ${theme.palette.error.dark}`,
    borderRadius: 4,
    boxShadow:
      "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
    margin: theme.spacing(1),
    flex: "1 1 0"
  },
  white: {
    color: "#fff"
  },
  failedText: {
    color: "#fff",
    marginTop: theme.spacing(2)
  },
  errorMessage: {
    backgroundColor: theme.palette.common.white,
    marginTop: theme.spacing(1),
    width: "100%",
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    wordBreak: "break-all",
    color: "#697882",
    flex: "1 1 0",
    overflow: "auto"
  }
}));

const UnCompletedCalculationState = ({
  state,
  calculationError
}: {
  state: JobState;
  calculationError?: string | null;
}) => {
  const { root, text, failed, white, failedText, errorMessage } = useStyles();

  return state === JobState.InProgress || state === JobState.Scheduled ? (
    <div className={root}>
      <CircularProgress color="primary" />
      <Typography className={text}>{`The calculation is ${state}`}</Typography>
    </div>
  ) : state === JobState.Failed ? (
    <div className={failed}>
      <ErrorIcon fontSize="large" className={white} />
      <Typography
        className={failedText}
      >{`This calculation is ${state}`}</Typography>
      <Typography className={errorMessage}>{calculationError}</Typography>
    </div>
  ) : null;
};
export default UnCompletedCalculationState;

import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { UseCaseManagementOverview } from "./schema/UseCaseManagementOverview";

export const queryUseCaseManagementList = gql`
  query UseCaseManagementOverview {
    cloudPlatform {
      useCases(latestVersionOnly: false) {
        detail {
          key
          name
          version
          description
          tags
        }
      }
    }
  }
`;

export const useUseCaseManagementOverview = () =>
  useQuery<UseCaseManagementOverview>(queryUseCaseManagementList);

import {
  Divider,
  IconButton,
  makeStyles,
  Tooltip,
  Typography
} from "@material-ui/core";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { useRef, useState } from "react";
import ActionBar from "../../ActionBar";
import EnvironmentDetails from "./EnvironmentDetails";

//@ts-ignore
const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 0,
    display: "flex",
    alignItems: "center"
  },
  divider: {
    height: 48,
    marginLeft: 12
  },
  iconButton: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
      borderRadius: 0,
      height: 48
    }
  },

  icon: { color: theme.palette.secondary.main }
}));
export default function NavigationActions({
  greeting
}: {
  greeting: JSX.Element;
}) {
  const { root, divider, iconButton, icon } = useStyles({});

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(true);
  };

  return (
    <div className={root}>
      <ActionBar />
      <EnvironmentDetails
        anchorEl={anchorRef.current}
        open={open}
        title="Current environment changelog"
        closePopper={() => {
          setOpen(false);
        }}
      >
        {title => (
          <>
            <Tooltip title={<Typography>{title}</Typography>}>
              <IconButton
                onClick={handleToggle}
                className={iconButton}
                ref={anchorRef}
              >
                <ScheduleIcon className={icon} />
              </IconButton>
            </Tooltip>
          </>
        )}
      </EnvironmentDetails>
      <Divider orientation="vertical" className={divider} />
      {greeting}
    </div>
  );
}

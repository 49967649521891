import { useMutation } from "@apollo/react-hooks";
import { LinearProgress } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import { AgGridEvent, GridApi } from "ag-grid-community";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AgGridReact } from "ag-grid-react";
import FullSize from "components/base/FullSize";
import LinkIconButton from "components/base/LinkIconButton";
import { ActionBarContent } from "features/shell";
import {
  DeleteUseCase,
  DeleteUseCaseVariables,
  mutationDeleteUseCase
} from "features/use-case";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  queryUseCaseManagementList,
  useUseCaseManagementOverview
} from "./hooks/useCaseManagementHooks";
import "./UseCaseManager.css";
import UseCaseToolsPanel from "./UseCaseToolsPanel";

export default function UseCaseManager() {
  const sizeColumnsToFit = (params: AgGridEvent) => {
    params.api.sizeColumnsToFit();
  };

  const selectFirstUseCase = (api: GridApi) => {
    api.forEachNode(node => {
      if (node.data) node.setSelected(node.rowIndex === 1);
    });
  };

  const history = useHistory();

  const { data, loading: loadingUseCases } = useUseCaseManagementOverview();
  const [lazyUseDeleteUseCase] = useMutation<
    DeleteUseCase,
    DeleteUseCaseVariables
  >(mutationDeleteUseCase, {
    refetchQueries: [{ query: queryUseCaseManagementList }]
  });

  const useCases = useMemo(() => {
    if (!data) return [];
    let result = data.cloudPlatform.useCases;
    if (!result) return [];
    return result;
  }, [data]);

  return (
    <>
      <ActionBarContent>
        <LinkIconButton
          to={"/use-case-manager/create"}
          title="Add use case"
          testId="add-use-case"
          icon={AddCircle}
        />
      </ActionBarContent>
      <FullSize className="ag-theme-balham" id="use-case-management-table">
        <AgGridReact
          columnDefs={[
            {
              field: "detail.key",
              headerName: "Key",
              rowGroup: true,
              showRowGroup: true,
              tooltip: ({ data }) => data?.detail.key
            },
            {
              field: "detail.name",
              headerName: "Name",
              tooltip: ({ data }) => data?.detail.name
            },
            {
              field: "detail.version",
              headerName: "Version",
              tooltip: ({ data }) => data?.detail.version
            },
            {
              field: "detail.tags",
              headerName: "Tags",
              tooltip: ({ data }) => data?.detail.tags.join(", ")
            },
            {
              field: "detail.description",
              headerName: "Description",
              tooltip: ({ data }) => data?.detail.description
            }
          ]}
          defaultColDef={{
            filter: "agTextColumnFilter",
            filterParams: {
              newRowsAction: "keep"
            },
            cellClass: "cell"
          }}
          onGridReady={sizeColumnsToFit}
          //@ts-ignore -> AG-2080 no ts definition in v19.0.0
          onFirstDataRendered={params => {
            sizeColumnsToFit(params);
            selectFirstUseCase(params.api);
          }}
          onRowGroupOpened={sizeColumnsToFit}
          onGridSizeChanged={sizeColumnsToFit}
          rowData={useCases || []}
          floatingFilter={true}
          groupDefaultExpanded={1}
          enableFilter
          enableSorting
          enableGroupEdit
          enableColResize
          rowSelection="multiple"
          rowDeselection
          // sort use cases on `group.key` property
          defaultGroupSortComparator={(a, b) => `${a.key}`.localeCompare(b.key)}
          frameworkComponents={{
            useCaseToolsPanel: UseCaseToolsPanel as any
          }}
          sideBar={{
            toolPanels: [
              {
                id: "useCaseTools",
                labelDefault: "Use Case Tools",
                labelKey: "useCaseTools",
                toolPanel: "useCaseToolsPanel",
                iconKey: "useCaseTools",
                toolPanelParams: {
                  history,
                  deleteUseCase: async (key: string, api: GridApi) => {
                    await lazyUseDeleteUseCase({
                      variables: { useCaseKey: key }
                    });
                  }
                }
              }
            ]
          }}
          onRowDataChanged={params => selectFirstUseCase(params.api)}
          onFilterChanged={params => selectFirstUseCase(params.api)}
          statusBar={{
            statusPanels: [
              {
                statusPanel: "agTotalRowCountComponent",
                align: "left"
              }
            ]
          }}
        />
        {loadingUseCases && <LinearProgress />}
      </FullSize>
    </>
  );
}

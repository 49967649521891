import { LinearProgress, makeStyles } from "@material-ui/core";
import _ from "lodash";
import { useState } from "react";
import Select, { components, Props } from "react-select";
import ValueContainerWithCustomPlaceholder from "./ValueContainerWithCustomPlaceholder";

const useStyles = makeStyles(() => ({
  cell: {
    display: "flex",
    alignItems: "center",
    height: "100%"
  },
  selectElement: {
    width: "100%"
  }
}));

interface IItem {
  label: string;
  value: string;
}

type UserGroupProps = {
  userGroups: string[];
  userName: string;
  allGroups?: string[];
  onUserGroupAdded: (user: string, group: string) => void;
  onUserGroupRemoved: (user: string, group: string) => void;
  loadingGroups: boolean;
  styles?: Props["styles"];
  placeholder?: string;
};

export default function UserGroups({
  userGroups,
  userName,
  allGroups = [],
  onUserGroupAdded,
  onUserGroupRemoved,
  loadingGroups,
  styles,
  placeholder
}: UserGroupProps) {
  const classes = useStyles();
  const [focused, setFocused] = useState(false);
  const handleChange = (options: any) => {
    const selectedGroupNames = (options as IItem[]).map(
      e => e.value
    ) as string[];
    const addedGroups = _.difference(selectedGroupNames, userGroups);
    if (addedGroups.length > 0) {
      onUserGroupAdded(userName, addedGroups[0]);
    } else {
      const deletedGroups = _.difference(userGroups, selectedGroupNames);
      onUserGroupRemoved(userName, deletedGroups[0]);
    }
  };

  return (
    <div className={classes.cell}>
      {loadingGroups ? <LinearProgress /> : null}
      <Select
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        isFocused={focused}
        className={classes.selectElement}
        //@ts-ignore
        styles={styles}
        isMulti
        isClearable={false}
        options={allGroups.map(getItem)}
        value={userGroups.map(getItem)}
        onChange={handleChange}
        placeholder={placeholder}
        theme={theme => {
          return {
            ...theme,
            colors: { ...theme.colors, primary: "#3AA9AE" }
          };
        }}
        components={{
          ValueContainer: placeholder
            ? ValueContainerWithCustomPlaceholder
            : components.ValueContainer
        }}
      />
    </div>
  );
}

function getItem(name: string): IItem {
  return { value: name, label: name };
}

import { Button, Grid, Link, TextField, Typography } from "@material-ui/core";
import "./authenticator-components.css";
import logo from "./internal/logo_petrol.svg";

type CustomSignUpRendererProps = {
  handleInputChange: (event: any) => void;
  resendCode: () => void;
  changeState: (state: string) => void;
  confirmSignUp: () => void;
  environment: string;
};
const CustomConfirmSignUpRenderer = ({
  handleInputChange,
  resendCode,
  changeState,
  confirmSignUp,
  environment
}: CustomSignUpRendererProps) => {
  return (
    <div className="container">
      <Grid container className="grid">
        <Grid item xs={12} md={5} lg={4} className="left">
          <div className="leftContent">
            <img src={logo} alt="logo" className="logo" />
            <form className="form" noValidate>
              <div className="formField">
                <TextField
                  name="code"
                  label="Confirmation Code *"
                  data-test="confirmationCode-input"
                  className="input"
                  variant="outlined"
                  onChange={handleInputChange}
                />
                <div className="forgetPassword">
                  Lost your code?{" "}
                  <Link
                    data-testid="resend-code"
                    className="link"
                    color="primary"
                    onClick={resendCode}
                  >
                    Resend code
                  </Link>
                </div>
              </div>

              <div className="confirmSignUpFooter">
                <Link
                  data-test="sign-in-create-account-link"
                  className="link"
                  color="primary"
                  onClick={() => changeState("signIn")}
                >
                  Back to Sign In
                </Link>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="button"
                  data-test="confirm-sign-up-button"
                  onClick={event => {
                    event.preventDefault();
                    confirmSignUp();
                  }}
                >
                  CONFIRM
                </Button>
              </div>
            </form>
          </div>
        </Grid>
        <Grid item xs={12} md={7} lg={8} className="right">
          <div className="rightContent">
            <Typography className="appTitle" variant="h2">
              Cloud Platform
            </Typography>
            <Typography className="environment">{environment}</Typography>
            <Typography className="appDescription">
              Create, manage and monitor your Use Cases
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default CustomConfirmSignUpRenderer;

import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles(theme => ({
  header: {
    width: "100%",
    display: "flex",
    color: "#fff"
  },
  left: {
    display: "flex",
    width: "50%",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    fontSize: 20,
    [theme.breakpoints.down("md")]: {
      fontSize: 14
    }
  },
  right: {
    display: "flex",
    justifyContent: "flex-end",
    width: "50%",
    alignItems: "center"
  },
  icon: { color: "#fff" }
}));

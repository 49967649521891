import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

export default function DeleteUserAction(params: {
  data: { name: string };
  handleUserDeleteRequest: (userName: string) => void;
}) {
  const {
    data: { name: userName },
    handleUserDeleteRequest
  } = params;

  return (
    <IconButton
      data-testid="delete-user"
      onClick={() => {
        handleUserDeleteRequest(userName);
      }}
    >
      <DeleteIcon />
    </IconButton>
  );
}

import { Button, Typography } from "@material-ui/core";
import Link from "components/base/Link";
import Tags from "components/base/Tags";
import React from "react";
import defaultIcon from "tile.svg";
import { UseCaseDetail } from "../hooks/topicsHooks";
import TechnicalChangelogButton from "../TechnicalChangelogButton";
import { useStyles } from "./UseCaseDetailStyle";

const UseCaseDetails: React.FC<UseCaseDetail> = ({
  image,
  version,
  name,
  description,
  tags,
  useCaseKey
}) => {
  const classNames = useStyles({});
  return (
    <div className={classNames.detail}>
      <div className={classNames.detailInner}>
        {image ? (
          <img src={image} className={classNames.image} alt={version} />
        ) : (
          <div className={classNames.imgInner}>
            <img
              src={defaultIcon}
              className={classNames.defaultIcon}
              alt="default"
            />
          </div>
        )}

        <div
          className={classNames.anotherDetails}
          data-testid="use-case-overview-name"
        >
          <Typography className={classNames.name}>{name}</Typography>
          <Typography className={classNames.version}>
            {version}
            {version !== "1.0.0" && (
              <TechnicalChangelogButton
                useCaseKey={useCaseKey}
                useCaseVersion={version}
              />
            )}
          </Typography>
        </div>
        <Link to="./public-api">
          <Button
            data-testid="topic-public-api"
            className={classNames.apiButton}
            variant="contained"
            color="primary"
          >
            PUBLIC API
          </Button>
        </Link>
      </div>
      {description ? (
        <Typography className={classNames.description}>
          {description}
        </Typography>
      ) : null}
      <Tags tags={tags} />
    </div>
  );
};

export default UseCaseDetails;

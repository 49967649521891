enum ConfigKey {
  GRAPHQL_ENDPOINT,
  TABLESET_VISUALIZER_URL,
  DATA_SOURCE_UNVERIFIED_BUCKET,
  REGION,
  USER_POOL_ID,
  USER_POOL_WEB_CLIENT_ID,
  USER_POOL_DOMAIN,
  IDENTITY_POOL_ID,
  ENVIRONMENT,
  CLOUDFRONT_URL,
  FEDERATED_CUSTOM_PROVIDER
  // add new config keys here ...
}

type ConfigKeyString = keyof typeof ConfigKey;

export function getKeys() {
  return Object.keys(ConfigKey).filter(key => isNaN(Number(key)));
}

export default ConfigKeyString;

import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  Typography
} from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";

export enum TestId {
  PermissionReport = "permissionReport"
}

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: theme.spacing(4),
    margin: theme.spacing(4),
    backgroundColor: "#F5F7F7"
  },
  permissions: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    paddingTop: theme.spacing(4)
  },
  warnColor: {
    color: "#b4015b"
  }
}));

interface AccessDeniedProps {
  missingPermissions: string[];
}
export default function AccessDenied({
  missingPermissions
}: AccessDeniedProps) {
  const classNames = useStyles();
  return (
    <div data-test={TestId.PermissionReport} className={classNames.container}>
      <Typography variant="h5" className={classNames.warnColor}>
        ACCESS DENIED!
      </Typography>

      <Box className={classNames.permissions}>
        <Typography variant="overline">
          You do not have sufficient permissions to access this content.
        </Typography>
        <List
          dense
          subheader={<ListSubheader>Missing permissions:</ListSubheader>}
        >
          {missingPermissions.map((permission: string) => (
            <ListItem key={permission}>
              <ListItemIcon>
                <LockIcon className={classNames.warnColor} />
              </ListItemIcon>
              <ListItemText primary={permission} />
            </ListItem>
          ))}
        </List>
      </Box>
    </div>
  );
}

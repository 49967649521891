import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { DateCellRenderer } from "components/grid/CellRenderers";
import ConfirmGridActionDialog from "components/grid/ConfirmGridActionDialog";
import Grid from "components/grid/Grid";
import { createColumn } from "components/grid/gridTypings";
import { UserName } from "features/user";
import React from "react";
import { DeepOmit } from "utils/typescript";
import { StreamingSessions_projects_workspace_streamingSessions } from "./schema/StreamingSessions";
import SessionActions from "./SessionActions";

const useStyles = makeStyles(theme => ({
  sessions: {
    minHeight: 500,
    display: "flex",
    flexDirection: "column",
    gridArea: "sessions"
  },
  icon: {
    margin: theme.spacing(2)
  }
}));

type StreamingSession = DeepOmit<
  StreamingSessions_projects_workspace_streamingSessions,
  "__typename"
>;

export interface SessionProps {
  canExpireSession: boolean;
  sessions?: StreamingSession[];
  expireSession: (userName: string) => void;
  loading: boolean;
}

export default function Sessions({
  canExpireSession,
  expireSession,
  sessions,
  loading
}: SessionProps) {
  const classNames = useStyles({});
  const [openExpireSessionDialog, setOpenExpireSessionDialog] =
    React.useState(false);
  const [userName, setUserName] = React.useState("");

  return (
    <div className={classNames.sessions}>
      <Typography variant="h5" gutterBottom>
        Seats taken by
      </Typography>

      <Grid<StreamingSession>
        rowData={sessions}
        isLoading={loading}
        columns={[
          createColumn({
            colDef: {
              field: "isConnected",
              headerName: "Is Connected",
              filter: "agSetColumnFilter",
              filterParams: { newRowsAction: "keep" },
              width: 120,
              suppressSizeToFit: true,
              cellRendererParams: {
                iconClass: classNames.icon
              }
            },
            render: ({ data: { isConnected }, iconClass }) =>
              isConnected ? (
                <Tooltip title="User is connected">
                  <FiberManualRecordIcon
                    className={iconClass}
                    color="primary"
                  />
                </Tooltip>
              ) : (
                <Tooltip title="User is not connected, but the session is still running">
                  <RadioButtonUncheckedIcon className={iconClass} />
                </Tooltip>
              )
          }),
          createColumn({
            colDef: {
              field: "user.name",
              headerName: "Name",
              filter: "agSetColumnFilter",
              filterParams: { newRowsAction: "keep" },
              sort: "asc"
            },
            render: ({ data: { user } }) => UserName({ data: user! })
          }),
          createColumn({
            colDef: {
              field: "startTime",
              headerName: "Start Time",
              filter: "agSetColumnFilter",
              filterParams: { newRowsAction: "keep" }
            },
            render: p => DateCellRenderer({ value: p.data.startTime })
          }),
          createColumn({
            colDef: {
              field: "actions",
              headerName: "Actions",
              width: 100,
              suppressMenu: true,
              suppressSorting: true,
              suppressSizeToFit: true,
              cellRendererParams: {
                handleExpireSession: (userName: string) => {
                  setUserName(userName);
                  setOpenExpireSessionDialog(true);
                },
                canExpireSession
              }
            },
            render: ({ data, canExpireSession, handleExpireSession }) => (
              <SessionActions
                userName={data.user?.name!}
                canExpireSession={canExpireSession}
                handleExpireSession={handleExpireSession}
              />
            )
          })
        ]}
      />

      <ConfirmGridActionDialog
        message={`You are about to expire the session of ${userName}`}
        onConfirm={() => expireSession(userName)}
        open={openExpireSessionDialog}
        onClose={() => setOpenExpireSessionDialog(false)}
      />
    </div>
  );
}

import { LinearProgress } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { History } from "history";
import { observer } from "mobx-react-lite";
import { Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import { RestrictedRealmContextProvider } from "features/access-management";
import { ModelProvider } from "model";
import { RouteModule, Title } from "pages/Routes";
import InvalidRoute from "./InvalidRoute";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: "#F5F7F7",
    flex: 1,
    overflow: "overlay", // scrollbar will overlay in the padding of the main div if necessary
    display: "flex",
    flexDirection: "column"
  },
  main: {
    padding: theme.spacing(3),
    flex: "1 1 auto",
    minWidth: 480,
    overflow: "auto"
  }
}));

const Shell = ({
  routes,
  history
}: {
  routes: RouteModule[];
  history: History;
}) => {
  const { root, main } = useStyles({});
  const debug = localStorage.getItem("debug") === "true";

  return (
    <RestrictedRealmContextProvider debug={debug}>
      <ModelProvider history={history}>
        <div className={root}>
          {/* The MaintenanceBanner should be displayed here */}
          <main className={main}>
            <Suspense fallback={<LinearProgress />}>
              <Switch>
                {routes.map(({ path, title, View }, index) => (
                  <Route key={index} exact path={path}>
                    <Title title={title}>
                      <View />
                    </Title>
                  </Route>
                ))}
                <Route path="/" component={InvalidRoute} />
              </Switch>
            </Suspense>
          </main>
        </div>
      </ModelProvider>
    </RestrictedRealmContextProvider>
  );
};

export default observer(Shell);

import { useApolloClient, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useCallback, useEffect } from "react";
import {
  QueryCalculationResultInputData,
  QueryCalculationResultInputDataVariables
} from "./schema/QueryCalculationResultInputData";
import {
  QueryCalculationResultOutputData,
  QueryCalculationResultOutputDataVariables
} from "./schema/QueryCalculationResultOutputData";
import {
  QueryCalculationSchema,
  QueryCalculationSchemaVariables
} from "./schema/QueryCalculationSchema";

export const schemaQuery = gql`
  query QueryCalculationSchema(
    $useCaseKey: String!
    $useCaseVersion: String
    $calculationId: ID!
  ) {
    cloudPlatform {
      useCases(useCaseKey: $useCaseKey, version: $useCaseVersion) {
        calculation(calculationId: $calculationId) {
          detail {
            state
            error
          }
          inputData {
            address
            tableSet {
              tables {
                schema {
                  displayName
                  name
                  columns {
                    displayName
                    name
                    nullable
                    typeName
                  }
                }
              }
            }
          }
          resultData {
            address
            tableSet {
              tables {
                schema {
                  displayName
                  name
                  columns {
                    displayName
                    name
                    nullable
                    typeName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useQueryCalculationSchema = (
  variables: QueryCalculationSchemaVariables
) => {
  const { data, loading, startPolling, stopPolling, error } =
    useQuery<QueryCalculationSchema>(schemaQuery, {
      variables,
      pollInterval: 5000
    });
  const state = data?.cloudPlatform.useCases?.[0]?.calculation?.detail.state;
  useEffect(() => {
    if (state === "InProgress" || state === "Scheduled") {
      startPolling(5000);
    } else {
      stopPolling();
    }
  }, [state, startPolling, stopPolling]);
  return { data, loading, error };
};

export const inputDataQuery = gql`
  query QueryCalculationResultInputData(
    $useCaseKey: String!
    $useCaseVersion: String
    $calculationId: ID!
    $names: [String]
    $skip: Int
    $take: Int
  ) {
    cloudPlatform {
      useCases(useCaseKey: $useCaseKey, version: $useCaseVersion) {
        calculation(calculationId: $calculationId) {
          inputData {
            tableSet {
              tables(names: $names) {
                rows(skip: $skip, take: $take)
              }
            }
          }
        }
      }
    }
  }
`;
export const useCalculationInputData = () => {
  const client = useApolloClient();
  const loadInputData = useCallback(
    async (
      useCaseKey: string,
      useCaseVersion: string | null,
      calculationId: string,
      name: string,
      skip: number,
      take: number
    ) => {
      const result = await client.query<
        QueryCalculationResultInputData,
        QueryCalculationResultInputDataVariables
      >({
        query: inputDataQuery,
        variables: {
          useCaseKey,
          useCaseVersion,
          calculationId,
          names: [name],
          skip,
          take
        }
      });
      if (!result) {
        return null;
      }
      if (result.errors) {
        throw new Error(result.errors.toString());
      }
      const { useCases } = result.data.cloudPlatform;
      return useCases?.[0]?.calculation?.inputData.tableSet.tables[0];
    },
    [client]
  );

  return { loadInputData };
};

export const resultDataQuery = gql`
  query QueryCalculationResultOutputData(
    $useCaseKey: String
    $useCaseVersion: String
    $calculationId: ID!
    $names: [String]
    $skip: Int
    $take: Int
  ) {
    cloudPlatform {
      useCases(useCaseKey: $useCaseKey, version: $useCaseVersion) {
        calculation(calculationId: $calculationId) {
          resultData {
            tableSet {
              tables(names: $names) {
                rows(skip: $skip, take: $take)
              }
            }
          }
        }
      }
    }
  }
`;

export const useCalculationOutputData = () => {
  const client = useApolloClient();

  const loadOutputData = useCallback(
    async (
      useCaseKey: string,
      useCaseVersion: string | null,
      calculationId: string,
      name: string,
      skip: number,
      take: number
    ) => {
      const result = await client.query<
        QueryCalculationResultOutputData,
        QueryCalculationResultOutputDataVariables
      >({
        query: resultDataQuery,
        variables: {
          useCaseKey,
          useCaseVersion,
          calculationId,
          names: [name],
          skip,
          take
        }
      });
      if (!result) {
        return null;
      }
      if (result.errors) {
        throw new Error(result.errors.toString());
      }
      const { useCases } = result.data.cloudPlatform;

      return useCases?.[0]?.calculation?.resultData?.tableSet.tables[0];
    },
    [client]
  );

  return { loadOutputData };
};

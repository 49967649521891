import {
  Avatar,
  Divider,
  makeStyles,
  Tooltip,
  Typography
} from "@material-ui/core";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Link from "components/base/Link";
import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import { getConfigValue } from "utils/config";
import EnvironmentDetails from "./EnvironmentDetails";
import logo from "./logo_petrol.svg";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    minWidth: 0,
    marginLeft: 12
  },
  link: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  avatarGroup: {
    display: "flex",
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  left: { backgroundColor: theme.palette.primary.main },
  right: {
    borderRadius: 0,
    borderBottomLeftRadius: 3,
    borderTopLeftRadius: 3,
    backgroundColor: "#82849f",
    cursor: "pointer",
    left: -8
  },
  divider: { height: 48, marginLeft: 12 },
  avatarLink: { color: "#ffffff", textDecoration: "none" },
  caption: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block"
    },
    textTransform: "capitalize",
    color: "#FFFFFF",
    position: "absolute",
    top: 0,
    left: 0,
    writingMode: "vertical-rl",
    transform: "rotate(180deg)",
    backgroundColor: theme.palette.secondary.main,
    height: "100%",
    textAlign: "center",
    cursor: "default",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    padding: "4px 0"
  }
}));

export default observer(() => {
  const { root, link, avatarGroup, left, right, divider, avatarLink, caption } =
    useStyles({});
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };
  const environment = (getConfigValue("ENVIRONMENT") || "").replace("_", " ");
  const environmentCaption = () => {
    return environment
      .split(" ")
      .reduce(
        (result, currentWord) => result + currentWord.charAt(0).toUpperCase(),
        ""
      );
  };
  return (
    <div className={root}>
      <Tooltip title={environment} placement="bottom">
        <Typography variant="caption" className={caption}>
          {environment}
        </Typography>
      </Tooltip>
      <Link to="/" className={link}>
        <img src={logo} alt="logo" height={50} />
      </Link>
      <div className={avatarGroup}>
        <AvatarGroup>
          <Avatar className={left}>
            <Link to="/" className={avatarLink}>
              CP
            </Link>
          </Avatar>

          <EnvironmentDetails
            anchorEl={anchorRef.current}
            open={open}
            title="Current environment changelog"
            closePopper={() => setOpen(false)}
            environmentCaption={environmentCaption()}
          >
            {(title, environmentCaption) => (
              <Tooltip title={<Typography>{title}</Typography>}>
                <Avatar
                  className={right}
                  ref={anchorRef}
                  onClick={handleToggle}
                >
                  {environmentCaption}
                </Avatar>
              </Tooltip>
            )}
          </EnvironmentDetails>
        </AvatarGroup>
        <Divider orientation="vertical" className={divider} />
      </div>
    </div>
  );
});

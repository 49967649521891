import { Auth } from "aws-amplify";
import { ConfirmSignUp } from "aws-amplify-react";
import { getConfigValue } from "utils/config";
import "./authenticator-components.css";
import CustomConfirmSignUpRenderer from "./CustomConfirmSignUpRenderer";

export default class CustomConfirmSignUp extends ConfirmSignUp {
  usernameFromAuthData(): string {
    const authData = this.props.authData;
    const username =
      authData && authData.user ? authData.user.username : authData;
    return username;
  }
  confirmSignUp = async () => {
    try {
      const { code } = this.inputs;
      const username = this.usernameFromAuthData();
      const user = await Auth.confirmSignUp(username, code);
      this.changeState("signedUp", user);
    } catch (err) {
      this.error(err);
    }
  };
  resendCode = async () => {
    const username = this.usernameFromAuthData();
    try {
      await Auth.resendSignUp(username);
    } catch (err) {
      this.error(err);
    }
  };

  showComponent() {
    const environment = (getConfigValue("ENVIRONMENT") || "").replace("_", " ");
    return (
      <CustomConfirmSignUpRenderer
        environment={environment}
        resendCode={this.resendCode}
        confirmSignUp={this.confirmSignUp}
        handleInputChange={this.handleInputChange}
        changeState={this.changeState}
      />
    );
  }
}

import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import {
  QueryUseCaseVersions,
  QueryUseCaseVersionsVariables
} from "./schema/QueryUseCaseVersions";

export type Versions = QueryUseCaseVersions;

export const queryUseCaseVersions = gql`
  query QueryUseCaseVersions($useCaseKey: String!) {
    cloudPlatform {
      useCases(useCaseKey: $useCaseKey) {
        detail {
          version
        }
      }
    }
  }
`;

export default function useQueryUseCaseVersions(
  variables: QueryUseCaseVersionsVariables
) {
  return useQuery<QueryUseCaseVersions, QueryUseCaseVersionsVariables>(
    queryUseCaseVersions,
    {
      variables
    }
  );
}

import { Button, makeStyles } from "@material-ui/core";
import { FileCopyOutlined, OpenInNew } from "@material-ui/icons";
import { ErrorBoxDialog } from "components/error";
import copy from "copy-to-clipboard";
import gql from "graphql-tag";
import useResettableMutation from "hooks/useResettableMutation";
import {
  CreateStreamingUrl,
  CreateStreamingUrlVariables
} from "./hooks/schema/CreateStreamingUrl";

export const mutationCreateStreamingUrl = gql`
  mutation CreateStreamingUrl($projectId: ID!) {
    projects {
      createStreamingUrl(projectId: $projectId)
    }
  }
`;

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.common.white,
    marginRight: 5
  }
}));

export default function ProjectStreamingButtons({
  projectId,
  isAvailable
}: {
  projectId: string;
  isAvailable: boolean;
}) {
  const classNames = useStyles();

  const [
    createStreamingUrlMutation,
    { error: mutationError, reset: resetError }
  ] = useResettableMutation<CreateStreamingUrl, CreateStreamingUrlVariables>(
    mutationCreateStreamingUrl
  );
  return (
    <>
      <Button
        color="primary"
        variant="contained"
        className={classNames.button}
        disabled={!isAvailable}
        data-testid="create-streaming-url"
        onClick={async () => {
          const result = await createStreamingUrlMutation({
            variables: {
              projectId
            }
          });
          const url = result?.data?.projects.createStreamingUrl;
          if (url) window.open(url, "_blank");
        }}
        endIcon={<OpenInNew />}
      >
        Start SCS
      </Button>

      <Button
        color="primary"
        variant="contained"
        className={classNames.button}
        disabled={!isAvailable}
        data-testid="copy-streaming-url"
        onClick={async () => {
          const result = await createStreamingUrlMutation({
            variables: {
              projectId
            }
          });
          const url = result?.data?.projects.createStreamingUrl;
          if (url) copy(url);
        }}
        endIcon={<FileCopyOutlined />}
      >
        Copy Streaming URL
      </Button>

      <ErrorBoxDialog apolloError={mutationError} onClose={resetError} />
    </>
  );
}

import {
  Card,
  CardHeader,
  ClickAwayListener,
  Fade,
  IconButton,
  makeStyles,
  Popper,
  Tooltip
} from "@material-ui/core";
import { ExitToApp } from "@material-ui/icons";
import { UserAvatar } from "features/user";
import { useRef, useState } from "react";

const useStyles = makeStyles(theme => ({
  paddingLeft: { paddingLeft: theme.spacing(2) },
  popper: {
    top: "69px !important",
    right: 8,
    zIndex: 1000,
    left: "unset !important"
  },
  cursor: { cursor: "pointer" },
  logoutIcon: { color: "#ffb900" }
}));

type UserInfo = {
  name: string;
  familyName: string;
  email: string;
  signOut: () => void;
};

export default function UserGreeting(props: UserInfo) {
  const { paddingLeft, popper, cursor, logoutIcon } = useStyles({});

  const { name: firstName, familyName: lastName, email, signOut } = props;
  const user = { firstName, lastName };

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={paddingLeft}>
        <div onClick={handleToggle} className={cursor} ref={anchorRef}>
          <UserAvatar large dataTest="logged-in-user" user={user} />
        </div>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          popperOptions={{
            modifiers: {
              computeStyle: { enabled: false, gpuAcceleration: false }
            }
          }}
          className={popper}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Card>
                <CardHeader
                  avatar={<UserAvatar aria-label="recipe" user={user} />}
                  action={
                    <Tooltip title="Sign Out">
                      <IconButton
                        data-test="sign-out-button"
                        aria-label="settings"
                        onClick={signOut}
                      >
                        <ExitToApp className={logoutIcon} />
                      </IconButton>
                    </Tooltip>
                  }
                  title={`${firstName} ${lastName}`}
                  subheader={email}
                />
              </Card>
            </Fade>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
}

import { Grid, Typography } from "@material-ui/core";
import { Auth } from "aws-amplify";
import { ForgotPassword } from "aws-amplify-react";
import { getConfigValue } from "utils/config";
import "./authenticator-components.css";
import ConfirmationForm from "./internal/ConfirmationForm";
import logo from "./internal/logo_petrol.svg";
import RenderRequestCodeForm from "./internal/RequestCodeForm";

export default class CustomForgotPassword extends ForgotPassword {
  constructor(props) {
    super(props);
    this.state = {
      codeSent: false,
      error: undefined,
      code: undefined,
      password: undefined,
      username: undefined
    };
  }
  sendCode = async event => {
    try {
      const { username } = this.inputs;

      event.preventDefault();
      await Auth.forgotPassword(username);
      this.setState({ codeSent: true });
    } catch (error) {
      this.error(error);
      this.setState({ error, codeSent: false });
    }
  };
  submitResetPassword = async event => {
    try {
      const { username, code, password } = this.inputs;
      event.preventDefault();
      this.setState({ username, code, password });
      const user = await Auth.forgotPasswordSubmit(username, code, password);
      this.setState({ codeSent: false });
      this.changeState("signIn", user);
    } catch (error) {
      this.error(error);
      this.setState({ error });
    }
  };
  resendCode = async event => {
    await this.sendCode(event);
  };

  showComponent() {
    const environment = (getConfigValue("ENVIRONMENT") || "").replace("_", " ");
    return (
      <div className="container">
        <Grid container className="grid">
          <Grid item xs={12} md={5} lg={4} className="left">
            <div className="leftContent">
              <img src={logo} alt="logo" className="logo" />
              {!this.state.codeSent ? (
                <RenderRequestCodeForm
                  handleInputChange={this.handleInputChange}
                  backToSignIn={() => this.changeState("signIn")}
                  sendCode={this.sendCode}
                />
              ) : (
                <ConfirmationForm
                  handleInputChange={this.handleInputChange}
                  resendCode={this.resendCode}
                  resetPassword={this.submitResetPassword}
                />
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={7} lg={8} className="right">
            <div className="rightContent">
              <Typography className="appTitle" variant="h2">
                Cloud Platform
              </Typography>
              <Typography className="environment">{environment}</Typography>
              <Typography className="appDescription">
                Create, manage and monitor your Use Cases
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

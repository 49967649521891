import { ApolloProvider } from "@apollo/react-hooks";
import { useLocalStore } from "mobx-react-lite";
import { getEnv, Instance, types } from "mobx-state-tree";
import { syncHistoryWithStore } from "mst-react-router";
import React, { FunctionComponent } from "react";
import { clientFactory } from "utils/graphql";
import routerModel from "./router.model";

export const RootModel = types.model({
  router: routerModel
});
export type IRootModel = Instance<typeof RootModel>;
const modelContext = React.createContext<IRootModel | null>(null);

export type ModelProviderProps = {
  history: any;
  instance?: IRootModel;
  children: React.ReactNode;
};

// todo: 7379 get rid of this file
export const ModelProvider: FunctionComponent<ModelProviderProps> = ({
  children,
  history,
  instance = RootModel.create(
    {
      router: {}
    },

    { client: clientFactory() }
  )
}) => {
  if (process.env.NODE_ENV === "development") {
    (window as any).mst = instance;
  }

  const observableInstance = useLocalStore(() => instance);
  syncHistoryWithStore(history, observableInstance.router);

  return (
    <ApolloProvider client={getEnv(instance).client}>
      <modelContext.Provider value={observableInstance}>
        {children}
      </modelContext.Provider>
    </ApolloProvider>
  );
};

export const useModel = () => {
  const model = React.useContext(modelContext);
  if (!model) {
    throw new Error("You have forgot to use StoreProvider.");
  }
  return model;
};

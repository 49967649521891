import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  makeStyles,
  TextField,
  Theme,
  Typography
} from "@material-ui/core";
import React, { useState } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    margin: theme.spacing(1),
    minHeight: 270
  },
  cardHeader: {
    backgroundColor: "#eaeded"
  },
  textField: {
    marginTop: theme.spacing(1),
    "& label.Mui-focused": {
      color: "#50bebe"
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#50bebe"
      }
    }
  }
}));

const DeleteUseCaseCard = ({
  onClose,
  onDelete
}: {
  onClose: () => void;
  onDelete: any;
}) => {
  const classes = useStyles();
  const [value, setValue] = useState("");
  return (
    <Card className={classes.card}>
      <CardHeader title="Delete use case?" className={classes.cardHeader} />
      <CardContent>
        <Typography>
          To confirm the deletion, type delete in the text field.
        </Typography>
        <TextField
          label="delete"
          variant="outlined"
          autoFocus
          value={value}
          onChange={event => setValue(event.target.value)}
          classes={{ root: classes.textField }}
        />
      </CardContent>
      <CardActions>
        <Button size="small" variant="contained" onClick={onClose}>
          Cancel
        </Button>
        <Button
          size="small"
          variant="contained"
          disabled={value !== "delete"}
          onClick={onDelete}
        >
          Delete
        </Button>
      </CardActions>
    </Card>
  );
};

export default DeleteUseCaseCard;

import { getConfigValue } from "utils/config";
import { queryFromObject } from "utils/url";

/**
 * Displays Vega-UI charting functionality. Vega-UI App is developed and deployed from http://locom-git:7990/projects/WEB/repos/vega-ui/.
 * @param {string} dataSource Data address to be visualized / used for chart configuration.
 * @param {string} chartSources Charts to be displayed. String concatenation could be used to use multiple chart sources (e.g. my-charts|use-case-input).
 *
 * *my-charts*: Displays all charts created by the current user for the current dataSource (Default).
 * *use-case-input*: Displays all charts that could be applied to use case input data. To filter for a specific use case key define *useCaseKey* param.
 * *use-case-result*: Displays all charts the could be applied to use case result data. Required additional params are useKeyKey (key of the use case) and *calculationId* Id of the result.
 *
 * @param {string} useCaseKey: Required for use-case-* chartSources.
 * @param {string} calculationId: Required for use-case-result chartSource. Use empty string if not required.
 * @param {string} hideNavBar: Hide navigation bar. Default: true.
 * @param {string} includeGreetings: Show Authenticator-Bar with logout when user has logged in. Default: false.
 * @param {string} lng: "Language to use. If no value is given the default browser language is used. Supported values are: en, de and fr."
 * @param {string} width: "Width of the iframe."
 * @param {string} height: "Height of the iframe."
 */

export default function Charts({
  dataSource,
  chartSources,
  useCaseKey,
  calculationId,
  useCaseVersion = null,
  hideNavBar = true,
  includeGreetings = false,
  lng = "en",

  width = "100%",
  height = "100%"
}: {
  dataSource: string;
  chartSources: "my-charts" | "use-case-input" | "use-case-result";
  useCaseKey: string;
  calculationId: string;
  useCaseVersion: null | string;
  hideNavBar?: boolean;
  includeGreetings?: boolean;
  lng?: "en" | "de" | "fr";

  width?: string;
  height?: string;
}) {
  const query = queryFromObject({
    dataSource,
    useCaseKey,
    useCaseVersion,
    chartSources,
    calculationId,
    hideNavBar,
    includeGreetings,
    lng
  });

  const src = `${getConfigValue("TABLESET_VISUALIZER_URL")}?${query}#/charts/`;

  return (
    <iframe
      width={width}
      height={height}
      src={src}
      title="Charts"
      frameBorder="0"
    />
  );
}

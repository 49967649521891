import { Button, makeStyles } from "@material-ui/core";
import Link from "components/base/Link";
import React from "react";
import { useQueryLatestVersion } from "../hooks/topicsHooks";

const useStyles = makeStyles(theme => ({
  right: {
    flex: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "capitalize",
    padding: theme.spacing(1),
    flexDirection: "column"
  },
  text: {
    textAlign: "center",
    color: "#fff",
    width: "100%",
    textTransform: "capitalize"
  },
  topicIcon: {
    color: "#fff"
  },
  publishButton: {
    color: "#FFFFFF",
    backgroundColor: theme.palette.primary.dark,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      opacity: 0.8
    }
  }
}));

type PublishNewVersionProps = {
  useCaseKey: string;
  useCaseVersion: string;
};

const PublishNewVersion: React.FC<PublishNewVersionProps> = ({
  useCaseKey,
  useCaseVersion
}) => {
  const jss = useStyles();
  const { data } = useQueryLatestVersion({ useCaseKey });
  const latestVersion = data?.cloudPlatform.latestUseCase?.detail.version;
  const isLatestVersion = latestVersion === useCaseVersion;
  if (!isLatestVersion) return null;
  return (
    <Link
      data-testid="topic-new-version"
      to={`/use-case-manager/use-case/${useCaseKey}@${useCaseVersion}/create`}
    >
      <Button className={jss.publishButton}>PUBLISH NEW VERSION</Button>
    </Link>
  );
};

export default PublishNewVersion;

import { Charts } from "components/chart";
import { formatDistanceToNow } from "date-fns";
import prettyBytes from "pretty-bytes";
import React from "react";

export const DateCellRenderer = params => {
  if (!params.value) return;
  const date =
    params.value instanceof Date
      ? params.value
      : new Date(Date.parse(params.value));
  return formatDistanceToNow(date, {
    includeSeconds: true,
    addSuffix: true
  });
};

export const BytesCellRenderer = params => {
  if (!params.value) return;
  return prettyBytes(params.value);
};

// React hooks are not supported yet (should be supported in 20.1.0 AG-2653/AG-2527)
export class DateTickerCellRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: formatDistanceToNow(props.value, {
        includeSeconds: true,
        addSuffix: true
      })
    };
  }
  componentDidMount() {
    this.intervalID = setInterval(() => this.tick(), 15000);
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  tick() {
    this.setState({
      date: formatDistanceToNow(this.props.value, {
        includeSeconds: true,
        addSuffix: true
      })
    });
  }
  render() {
    return <span>{this.state.date}</span>;
  }
}

export class DisabledGroupCellRenderer extends React.Component {
  render() {
    return (
      <span className="ag-group-contracted">
        <span
          className="ag-icon ag-icon-expanded"
          style={{ backgroundImage: "none" }}
        />
        <span className="ag-group-value">{this.props.value}</span>
      </span>
    );
  }
}

export const ResultChartsCellRenderer = props => {
  const {
    data: { id, state, resultAddress },
    useCaseKey,
    useCaseVersion,
    agGridReact: {
      gridOptions: { detailRowHeight = 400 }
    }
  } = props;

  if (state !== "Completed") return null;

  return (
    <Charts
      dataSource={resultAddress}
      chartSources="use-case-result"
      useCaseKey={useCaseKey}
      useCaseVersion={useCaseVersion}
      calculationId={id}
      height={detailRowHeight}
    />
  );
};

import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { trimEnd } from "lodash";
import React from "react";
import { Redirect } from "react-router-dom";

import { LatestVersion, LatestVersionVariables } from "./schema/LatestVersion";

const queryLatestVersion = gql`
  query LatestVersion($useCaseKey: String!) {
    cloudPlatform {
      useCase(useCaseKey: $useCaseKey) {
        detail {
          version
        }
      }
    }
  }
`;

const RedirectToLatestUseCaseVersion: React.FC<{
  url: string;
  useCaseKey: string;
}> = ({ url, useCaseKey }) => {
  const { data, loading } = useQuery<LatestVersion, LatestVersionVariables>(
    queryLatestVersion,
    { variables: { useCaseKey } }
  );
  if (loading) return <span> redirect to latest version ... </span>;
  if (!data) return null;
  if (!data.cloudPlatform.useCase) return null;
  const useCaseVersion = data.cloudPlatform.useCase.detail.version;
  return <Redirect to={`${trimEnd(url, "/")}/${useCaseVersion}`} />;
};

export default RedirectToLatestUseCaseVersion;

import { Grid, TextField } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Dispatch, FormEvent } from "react";
import { Action, State } from "./hooks/useCreateUserReducer";
import { useQueryGetGroupsList } from "./hooks/usersHooks";
import UserGroups from "./UserGroups";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      margin: theme.spacing(2, 0, 1, 0)
    }
  })
);

interface AddUserFormProps {
  state: State;
  dispatch: Dispatch<Action>;
  onSubmit: (event: FormEvent) => void;
}

export default function AddUserForm({
  state,
  dispatch,
  onSubmit
}: AddUserFormProps) {
  const classes = useStyles();
  const { data, loading: loadingGroups } = useQueryGetGroupsList();
  const groupNames =
    data?.accessManagement.groups.map(group => group.name) ?? [];

  return (
    <form id="userForm" onSubmit={onSubmit}>
      <Grid container direction="column">
        <TextField
          id="first-name"
          label="First Name"
          name="first-name"
          inputProps={{ maxLength: 35, "data-testid": "first-name" }}
          value={state.firstName}
          required
          onChange={e => {
            const value = e.target.value;
            dispatch({ type: "SET_FIRST_NAME", value });
          }}
          margin="normal"
          variant="outlined"
        />

        <TextField
          id="last-name"
          label="Last Name"
          name="last-name"
          inputProps={{ maxLength: 35, "data-testid": "last-name" }}
          fullWidth
          value={state.lastName}
          required
          onChange={e => {
            e.persist();
            const value = e.target.value;
            dispatch({ type: "SET_LAST_NAME", value });
          }}
          margin="normal"
          variant="outlined"
        />

        <TextField
          id="email"
          label="Email"
          name="email"
          inputProps={{ maxLength: 256, "data-testid": "email" }}
          required
          value={state.email}
          onChange={e => {
            const value = e.target.value;
            dispatch({ type: "SET_EMAIL", value });
          }}
          margin="normal"
          variant="outlined"
          type="email"
        />

        <Grid item className={classes.select}>
          <UserGroups
            styles={{
              multiValueLabel: base => ({ ...base, fontSize: "100%" }),
              control: (base, state) => ({
                ...base,
                padding: "9px 0",
                boxShadow: "none",
                borderColor: "rgba(0, 0, 0, 0.23)",
                "&:focus-within": {
                  borderColor: ` ${state.theme.colors.primary}`,
                  borderWidth: 2,
                  position: "relative",
                  margin: -1
                },
                "&:hover": { borderColor: "rgba(0, 0, 0, 0.87)" }
              }),
              valueContainer: base => ({
                ...base,
                overflow: "visible"
              }),
              placeholder: (base, state) => {
                const selected =
                  state.hasValue ||
                  state.selectProps.inputValue ||
                  state.selectProps.isFocused;
                const hasValueOrFocused =
                  state.selectProps.inputValue || state.selectProps.isFocused;
                return {
                  ...base,
                  position: "absolute",
                  top: 0,
                  left: 0,
                  display: "block",
                  transform: selected
                    ? "translate(2px, -22px) scale(0.75)"
                    : "translate(12px, 5px) scale(1)",
                  transition: "top 0.1s, font-size 0.1s",
                  padding: selected ? "0 6px 0 4px" : 0,
                  fontSize: "1rem",
                  color: hasValueOrFocused
                    ? state.theme.colors.primary
                    : "rgba(0, 0, 0, 0.54)",
                  backgroundColor: "#ffffff"
                };
              }
            }}
            userGroups={state.groups}
            userName={state.email}
            allGroups={groupNames}
            loadingGroups={loadingGroups}
            onUserGroupAdded={(_, group) => {
              dispatch({
                type: "ADD_GROUP",
                value: group
              });
            }}
            onUserGroupRemoved={(_, group) => {
              dispatch({
                type: "REMOVE_GROUP",
                value: group
              });
            }}
            placeholder="Groups"
          />
        </Grid>
      </Grid>
    </form>
  );
}
